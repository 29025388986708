@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'bootstrap/dist/css/bootstrap-grid.css';
@import 'bootstrap/dist/css/bootstrap-reboot.css';
@import 'swiper/css/swiper.min.css';
@import 'photoswipe/dist/photoswipe.css';
/*
@font-face {
  font-family: "DINOffcPro";
  src: url("../fonts/DINOffcPro-Bold.woff");
} */

@font-face{
    font-family:"DIN Next W04";
    src:url("../fonts/104891d3-8652-43b8-b9b1-aa7b40f836d3.woff2") format("woff2"),url("../fonts/446bfb67-e695-425a-8024-5c367ba0d8f1.woff") format("woff");
    font-weight: 800;
    font-style: normal;
}
@font-face{
    font-family:"DIN Next W01";
    src:url("../fonts/44e7b0fa-6c8d-43c2-b19e-f1e3ce9ea57c.woff2") format("woff2"),url("../fonts/c5a7f89e-15b6-49a9-8259-5ea665e72191.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face{
    font-family:"DIN Next W01";
    src:url("../fonts/f37f8098-b16e-405f-ab24-bd595a5d5762.woff2") format("woff2"),url("../fonts/c5635d60-91fa-4e4b-8a51-41c97fc744c3.woff") format("woff");
    font-weight: 400;
    font-style: italic;
}
@font-face{
    font-family:"DIN Next W01";
    src:url("../fonts/be607c9f-3c81-41b4-b7ad-17af005c80bb.woff2") format("woff2"),url("../fonts/4bf0ead4-e61b-4992-832b-6ff05828d99f.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}
@font-face{
    font-family:"DIN Next W01";
    src:url("../fonts/5e7b11e8-112d-42fe-a35c-edaca8e64677.woff2") format("woff2"),url("../fonts/00ebffbe-b1fe-4913-b8d8-50f0ba8af480.woff") format("woff");
    font-weight: 500;
    font-style: italic;
}
@font-face{
    font-family:"DIN Next W01";
    src:url("../fonts/121784c1-863c-4e49-b682-625ea7de55b0.woff2") format("woff2"),url("../fonts/9949035f-6226-4080-a14a-bf7d94ffb1e1.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}
@font-face{
    font-family:"DIN Next W01";
    src:url("../fonts/4e20348d-41a7-4295-94ab-2b1019e9d453.woff2") format("woff2"),url("../fonts/140a0a6f-f218-4071-8eb8-f2bc45ed5121.woff") format("woff");
    font-weight: 700;
    font-style: italic;
}

:root {
    --clearfix: {
        &:before,
        &:after {
            content: ' ';
            display: table;
        }

        &:after {
            clear: both;
        }
    }

    --center-vertical: {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    --center-all: {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

/* Main */

body {
    @media (--full-grid-viewport) {
        overflow-x: hidden;
    }

    @media print {
        &.modal-open {
            position: fixed;
            max-height: 100%;
            margin: 0 !important;
            padding: 0 !important;
            overflow: hidden !important;
        }
    }
}

.grid-wrapper {
    max-width: 1200px;
    margin: 0 auto;
}

.main-container {
    margin-top: 70px;

    @media (--desktop-viewport) {
        margin-top: 158px;
    }
}

.container {
    max-width: none;
    padding: 0;
}

/* helper */
.padding {
    padding: 0 1em;

    @media (--full-grid-viewport) {
        padding: 0;
    }
}

.no-gutters {
    padding-right: 0;
    padding-left: 0;
}

.sm-gutters {
    padding-right: var(--unit-1);
    padding-left: var(--unit-1);
}

/* Textstyles */

a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
table {
    font-family: var(--font-Family);
    color: var(--gray-darkest);
    font-size: var(--fontsize-normal);
    hyphens: manual;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
    font-weight: bold;
}

h1 {
    font-size: var(--mobile-h1);
    hyphens: auto;

    @media (--small-viewport) {
        font-size: var(--h1);
    }
}

h2 {
    font-size: var(--mobile-h2);

    @media (--small-viewport) {
        font-size: var(--h2);
    }

    &.fws {
        padding: 0 var(--unit-1);

        @media (--full-grid-viewport) {
            padding: 0;
        }
    }
}

h3 {
    font-size: var(--mobile-h3);

    @media (--small-viewport) {
        font-size: var(--h3);
    }
}

h4 {
    font-size: var(--mobile-h4);

    @media (--small-viewport) {
        font-size: var(--h4);
    }
}

a {
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;

    &:hover {
        text-decoration: none;
        color: var(--brand-primary);
    }

    &.white {
        color: var(--white);
    }

    &[data-toggle='modal'] {
        color: var(--brand-primary);
        font-weight: normal;
        text-decoration: underline;
        padding-right: 20px;

        &:after {
            content: url(../img/svg/info-link.svg);
            margin-left: 2px;
            margin-top: 3px;
            position: absolute;
        }

        &:hover {
            color: var(--blue-darker);
            text-decoration: underline;
        }
    }
}

figcaption {
    font-size: var(--figcaption);
    line-height: 20px;
    color: var(--gray-dark);
}

.richText {
    font-family: "DIN Next W01", monospace;

    & p + h2,
    & p + h3,
    & p + h4,
    & p + h5,
    & p + h6 {
        margin-top: var(--unit-3);
    }

    & a {
        color: var(--brand-primary);
        font-weight: normal;
        text-decoration: underline;
        display: inline;

        &.hide-after {
            margin-right: 0 !important;
            &:after {
                display: none;
            }
        }

        &[href^='mailto'] {
            margin-right: 20px;

            &:after {
                content: url(../img/svg/mail.svg);
                margin-left: 2px;
                margin-top: 2px;
                position: absolute;
            }
        }

        &[href$='.pdf'] {
            margin-right: 20px;

            &:after {
                content: url(../img/svg/pdf.svg);
                position: absolute;
            }
        }

        &[href^="http://"], &[href^="https://"]
        {
            margin-right: 20px;

            &:after {
                content: url(../img/svg/extern_link.svg);
                position: absolute;
            }
        }

        &[data-bs-toggle="modal"]
        {
            color: var(--brand-primary);
            font-weight: normal;
            text-decoration: underline;
            padding-right: 20px;

            &:after {
                content: url(../img/svg/info-link.svg);
                margin-left: 2px;
                margin-top: 3px;
                position: absolute;
            }

            &:hover {
                color: var(--blue-darker);
                text-decoration: underline;
            }
        }

        &:hover {
            color: var(--blue-darker);
            text-decoration: underline;
        }
    }

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
        & a {
            color: var(--gray-darkest);
            font-weight: bold;
        }
    }
}

/* Buttons */

.main-container {
    & button {
        text-align: left;
        padding: 15px 25px !important;
        transition: background-color 0.1s ease, color 0.2s ease;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: manual;
        width: auto;
        min-height: 56px;
    }
}

button {
    background: none;
    border: none;
    text-transform: uppercase;
    padding: 0.5em 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;

    & span {
        margin-top: 2px;
    }

    &.button--primary {
        width: 100%;
        background: var(--brand-primary);
        color: var(--white);
        height: auto;

        & svg {
            fill: var(--brand-primary);
            stroke: var(--white);
        }

        &:active {
            background: var(--blue-darker);
        }

        &:hover {
            color: var(--gray-light);

            & svg {
                stroke: var(--gray-light);
                transform: translateX(5px);
            }
        }

        &.inverted {
            background: var(--white);
            color: var(--brand-primary);

            & svg {
                fill: var(--white);
                stroke: var(--brand-primary);
            }

            &:hover {
                color: var(--blue-darkest);

                & svg {
                    stroke: var(--blue-darkest);
                }
            }
        }
    }

    &.button--secondary {
        width: 100%;
        background: var(--brand-secondary);
        color: var(--blue-darkest);
        height: auto;

        & svg {
            fill: var(--brand-secondary);
            stroke: var(--blue-darkest);
        }

        &:hover {
            color: var(--blue-dark);

            & svg {
                stroke: var(--blue-dark);
                transform: translateX(5px);
            }
        }
    }

    &.button-tertiary {
        width: 100%;
        align-items: center;
        padding: 0;
        margin: var(--unit-1) 0;
        height: auto;

        & svg {
            height: 32px;
            fill: var(--white);
            stroke: var(--brand-primary);
            margin: 0;
        }

        & h4 {
            color: var(--brand-primary);
            margin: 0;
            transition: color 0.2s ease;
        }

        & > *:first-child {
            margin-right: 0.5em;
        }
    }

    & svg {
        height: 16px;
        width: 28px;
        margin-left: 8px;
        flex-shrink: 0;
        align-self: center;
        transition: fill 0.2s ease, stroke 0.2s ease, transform 0.25s ease-out;
    }

    & h3 {
        transition: color 0.2s ease;
    }

    &.icon-button svg {
        width: 30px;
        height: 30px;
    }

    &.icon {
        justify-content: space-between;
    }

    &:active {
        background: var(--gray-lighter);
    }

    &.read-more {
        background: var(--gray-lighter);
        color: var(--blue-darkest);
    }

    &.disabled {
        background: var(--gray-dark);
        cursor: auto;
    }
}

/* Select Box */

.custom-select-wrap {
    outline: none;
    font-family: var(--font-Family);
    position: relative;

    & span.custom-selector {
        display: none;
    }

    & button.custom-select-button {
        border-radius: 0;
        text-transform: uppercase;
        font-weight: 600;
        height: 50px;
        width: 100%;
        min-height: 0;
        min-width: 0;
        display: flex;
        justify-content: space-between;
        padding: var(--unit-2) !important;

        &.active {
            & svg {
                transform: rotate(180deg);
            }
        }

        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
        }

        & option {
            text-transform: none;
        }

        & span {
            margin-top: 4px;
            line-height: var(--unit-2);
        }

        & svg {
            width: 30px;
            height: 30px;
            stroke: var(--brand-primary);
            transition: transform 0.15s ease;
        }

        &.select--primary-inverted {
            border: 2px solid var(--brand-primary);
            color: var(--brand-primary);
            background-color: white;

            &.custom-select:focus {
                outline: none;
                border: 2px solid var(--brand-primary);
            }
        }

        &.select--secondary {
            border: none;
            background-color: transparent;
            -webkit-appearance: none;
            color: var(--brand-primary);

            &.custom-select:focus {
                outline: none;
                border: 2px solid var(--brand-primary);
            }
        }
    }

    & ul.select-options {
        padding: 0;
        margin: 0;
        list-style-type: none;
        position: absolute;
        z-index: 1000;
        left: 0;
        width: 100%;

        & .selection {
            padding: 15px;
            min-height: 50px;
            text-transform: uppercase;
            background: white;
            border: 2px solid var(--brand-primary);
            border-top: none;
            cursor: pointer;
            transition: color 0.3s ease, background 0.3s ease;

            &:hover,
            &.active {
                color: white;
                background: var(--brand-primary);
            }
        }
    }
}

/* SVG */

.main-svg {
    display: none;
}

svg {
    stroke: var(--gray);
    stroke-width: 2;
}

/* Form Field */

form {
    & label {
        width: 100%;
    }

    & input,
    & textarea {
        background: var(--gray-lighter);
        border: 1px solid var(--gray-light);
        outline: none;
    }

    & textarea,
    & input[type='text'],
    & input[type='tel'],
    & input[type='email'] {
        width: 100%;
        padding: var(--unit-2);
    }

    & textarea,
    & input[type='password'] {
        width: 100%;
        padding: var(--unit-2);
    }

    & .radio,
    & .checkmark {
        display: block;
        position: relative;
        padding-left: var(--unit-4);
        margin-bottom: var(--unit-4);
        margin: 0 var(--unit-2);

        &:hover span {
            border-color: var(--brand-primary);
        }

        & input {
            display: none;
        }

        & input[type='radio'] {
            & + span {
                border-radius: 50%;
            }

            &:checked + span {
                background: var(--brand-primary);
                border: 2px solid var(--white);
                box-shadow: 0px 0px 0px 2px var(--brand-primary);
            }
        }

        & input[type='checkbox'] {
            &:checked + span {
                background-color: var(--brand-primary);
                border-color: var(--brand-primary);
                background-image: url(../img/svg/checkmark.svg);
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        & span {
            background: var(--white);
            background-repeat: no-repeat;
            background-position: center;
            width: 20px;
            height: 20px;
            display: inline-block;
            border: 2px solid var(--gray);
            position: absolute;
            top: 2px;
            left: 0;
            transition: all 0.2s linear;
        }
    }
}

/* Focus styling */
button:focus-visible,
a:focus-visible {
    outline: 2px solid var(--white) !important;
    box-shadow: 0 0 2px 5px var(--brand-primary) !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0 !important;
}

a:focus-visible {
    display: inherit;
}

/* Global scroll animation helper classes */

.scroll-animation {
    opacity: 0;
    transform: translate3d(0, 2vh, 0);
    will-change: transform, opacity;
    transition: opacity 1s ease, transform 1.2s ease;

    &.in-viewport {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.scroll-animation-delay-1 {
    @media (--desktop-viewport) {
        transition-delay: calc(var(--delay) * 1);
    }
}

.scroll-animation-delay-2 {
    @media (--desktop-viewport) {
        transition-delay: calc(var(--delay) * 2);
    }
}

.scroll-animation-delay-3 {
    @media (--desktop-viewport) {
        transition-delay: calc(var(--delay) * 3);
    }
}

.scroll-animation-delay-4 {
    @media (--desktop-viewport) {
        transition-delay: calc(var(--delay) * 4);
    }
}

.scroll-animation-delay-5 {
    @media (--desktop-viewport) {
        transition-delay: calc(var(--delay) * 5);
    }
}

.button-imitate {
    display:inline-grid;
    width:100%;
    height:100%;
    -webkit-box-pack:start;
    -ms-flex-pack:start;
    justify-content:flex-start;
    border:2px solid #238cc3;
    text-align: left;
    padding:15px 25px !important;
    -webkit-transition:background-color .1s ease,color .2s ease;
    transition:background-color .1s ease,color .2s ease;
    word-wrap:break-word;
    -webkit-hyphens:manual;
    -ms-hyphens: manual;
    hyphens:manual;
    min-height:56px;
}
/* Print formating */
@media print {
  /* General */
  .container,
  body {
    min-width: 0 !important;
    -webkit-print-color-adjust: exact;
  }

  .main-container {
    margin-top: 0;
  }

  .scroll-animation {
    opacity: 1;
  }

  a {
    text-decoration: none !important;
  }

  section {
    page-break-inside: avoid;
  }

  /* Header */
  .navigation-shortcuts {
    position: static !important;
  }

  nav {
    position: static !important;
  }

  /* Hero-Carousel */
  .hero-carousel {
    & ol {
      display: none !important;
    }
  }

  /* Link-Module */
  .link_module {
    & a {
      padding: 0;
    }
  }

  /* Teaser */
  .teaser {
    padding: 0;

    & .read-more-container.closed .teaser-content {
      height: 100% !important;
      -webkit-line-clamp: 100 !important;

      & .read-more-container.closed .text-content {
        height: 100% !important;
        -webkit-line-clamp: 100 !important;
      }
    }

    & .read-more {
      display: none !important;
    }
  }

  /* Accordeon */
  #accordeon {
    page-break-inside: auto;
  }

  #accordeon .collapse {
    display: block !important;
  }

  /* Cookie */
  .cookie {
    display: none;
  }

  /* Footer */
  footer {
    page-break-inside: avoid;
  }
}

:root {
  /* Colors */
  --brand-primary: #238cc3;
  --brand-secondary: #f6f7f8;

  --gray: #757982; /* Main font color */

  --blue-darkest: #005480;
  --blue-darker: #006ea8;
  --blue-dark: var(--brand-primary);
  --blue-lighter: #5fb5e2;
  --blue-lightest: #bee1f3;

  --gray-darkest: #54575f;
  --gray-darker: #76777b;
  --gray-dark: #a5a9b2;
  --gray-light: #e4e4e4;
  --gray-lighter: #f6f7f8;
  --gray-lightest: #fbfbfa;
  --white: #ffffff;

  --black-overlay: #14140c;

  /* Units */
  --unit-1: 0.5em;
  --unit-2: 1em;
  --unit-3: 1.5em;
  --unit-4: 2em;
  --unit-5: 2.5em;

  --unit-1-rem: 0.5rem; /* 8px */
  --unit-2-rem: 1rem; /* 16px */
  --unit-3-rem: 1.5rem; /* 24px */
  --unit-4-rem: 2rem; /* 32px */
  --unit-5-rem: 2.5rem; /* 40px */

  /* Helper */
  --full-width: 1200px;

  --spacing-top-desktop: 50px;
  --spacing-top-mobile: 10px;
  --spacing-top-small: 40px;

  /* Fonts */
  --font-Family: 'DIN Next W01', Arial, sans-serif;
  --fontsize-normal: 16px;
  --fontsize-small: 14px;
  --fontsize-smaller: 12px;

  --h1: 52px;
  --h2: 36px;
  --h3: 24px;
  --h4: 16px;
  --mobile-h1: 26px;
  --mobile-h2: 22px;
  --mobile-h3: 18px;
  --mobile-h4: 16px;
  --figcaption: 12px;

  /* Scroll animation delay */
  --delay: 0.3s;
}

/* Custom viewports */
@custom-media --xsmall-viewport (min-width: 28em); /* ~ 445px */
@custom-media --small-viewport (min-width: 40em); /* ~ 640px */
@custom-media --tablet-viewport (min-width: 48em); /* ~ 768px */
@custom-media --desktop-viewport (min-width: 62em); /* ~ 992px */
@custom-media --full-grid-viewport (min-width: 77em); /* ~ 1230px */
@custom-media --large-viewport (min-width: 90em); /* ~ 1440px */
@custom-media --xlarge-viewport (min-width: 120em); /* ~ 1920px */

@custom-media --xsmall-viewport-max (max-width: 28em); /* ~ 445px */
@custom-media --small-viewport-max (max-width: 40em); /* ~ 640px */
@custom-media --tablet-viewport-max (max-width: 48em); /* ~ 768px */
@custom-media --desktop-viewport-max (max-width: 62em); /* ~ 992px */
@custom-media --full-grid-viewport-max (max-width: 77em); /* ~ 1230px */
@custom-media --large-viewport-max (max-width: 90em); /* ~ 1440px */
@custom-media --xlarge-viewport-max (max-width: 120em); /* ~ 1920px */

.breadcrumb-outer {
  & .spacing-s:first-child {
    height: 0;
    padding: 0.5em;

    @media (--small-viewport) {
      padding: 0.7em;
    }
  }

  & #breadcrumb {
    & .icon {
      width: 15px;
      height: 15px;
    }

    & p {
      display: flex;
      align-items: center;
      margin-bottom: 0;

      & svg {
        margin-right: calc(var(--unit-1) / 2);
        stroke: var(--gray-dark);
        stroke-width: 0.8;
      }

      & span {
        margin: 4px;
        margin-bottom: 0;
        display: block;
        color: var(--gray-dark);

        & a {
          font-weight: 300;
          color: var(--gray-dark);
        }
      }
    }
  }
}
.gallery {
  position: relative;
  overflow: hidden;
  background: var(--gray-lighter);

  --slide-item-height-low: 200px;
  --slide-item-height-high: 300px;

  .title-wrap {
    & h2 {
      margin-bottom: var(--unit-3);

      @media (--tablet-viewport) {
        margin-bottom: var(--unit-1);
      }

      &.no-padding {
        padding: 0;
      }
    }
  }

  /* SWIPER STUFF */

  & .swiper-wrapper {
    @media (--tablet-viewport) {
      margin: var(--unit-5) 0;
    }
  }

  & .swiper-slide {
    padding: initial;
    display: initial;
    margin: initial;
    display: block;
    flex-shrink: initial;

    & img {
      height: var(--slide-item-height-low);

      @media (--tablet-viewport) {
        height: var(--slide-item-height-high);
      }
    }

    & .image-caption {
      margin: var(--unit-3) 0;

      & .title {
        display: inline;
        line-height: 1.2;
        font-weight: bold;
        margin-bottom: 0;

        @media (--tablet-viewport) {
          font-size: var(--h3);
        }
      }

      & .year {
        display: inline;
        line-height: 1.2;
        font-weight: bold;
        margin-bottom: 0;
      }

      & .artist,
      .copyright {
        text-transform: initial;
        margin-top: var(--unit-1);
        font-size: var(--fontsize-small);
        font-family: var(--font-Family);
        line-height: 1.125;
      }

      & .text-link {
        display: flex;
        color: var(--brand-primary);
        font-weight: 500;
        padding: 0 !important;
        margin: 0;
        margin-top: var(--unit-2);
        justify-content: left;
        align-items: center;

        & span {
          display: block;
          margin-top: 4px;
        }

        &:hover {
          color: var(--blue-darker);
        }

        &:hover svg {
          stroke: var(--blue-darker) !important;
          transform: translateX(5px);
        }

        & svg {
          width: 24px;
          height: 24px;
          margin-left: 4px;
          margin-top: 3px;
          fill: none;
          stroke: var(--brand-primary);
        }
      }
    }

    &.category-slide {
      padding-left: 2em;
    }

    & .category {
      position: absolute;
      top: 0;
      left: 0;
      left: 1.4em;
      transform-origin: 0 0;
      transform: rotate(90deg);
      font-size: var(--h3);
    }

    & .video-container {
      position: relative;

      & .video-overlay {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
      }

      & embetty-video {
        aspect-ratio: 16 / 9;
        border-radius: 0;
        border: 0;
        display: block;

        height: var(--slide-item-height-low);
        min-width: calc(var(--slide-item-height-low) * (16 / 9));

        @media (--tablet-viewport) {
          height: var(--slide-item-height-high);
          min-width: calc(var(--slide-item-height-high) * (16 / 9));
        }
      }

      .title {
        font-size: 20px;
        text-transform: none;
        margin: 1.3em 0;
      }
    }
  }

  & .swiper-scrollbar {
    display: none;
    border-radius: 0;
    height: 10px;
    border: 1px solid var(--brand-primary);
    cursor: grab;
    background: none;
  }

  & .swiper-scrollbar-drag {
    border-radius: 0;
    background: var(--brand-primary);
  }

  & .navigation-buttons {
    & .navigation-buttons-inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & .swiper-button-prev,
      & .swiper-button-next {
        background-image: none;
        background-color: var(--white);
        width: var(--unit-5);
        height: var(--unit-5);
        transition: all 0.3s ease;
        position: relative;
        background: var(--brand-primary);
        left: 0;
        right: 0;

        & svg {
          outline: none;
          stroke: var(--white);
          width: 15px;
          height: 15px;
        }

        &::after {
          display: none;
        }
      }
    }
  }

  & .swiper-button-prev.swiper-button-disabled,
  & .swiper-button-next.swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }

  &.show-scrollbar {
    .swiper-scrollbar {
      display: block;
    }
  }

  /* VARIANTS */

  &.default {
    position: relative;

    & .navigation-buttons {
      .navigation-buttons-inner {
        justify-content: flex-start;
        gap: 16px;

        .swiper-button {
          margin-top: 0;
          top: 0;
        }

        & .swiper-button-prev.swiper-button-disabled,
        & .swiper-button-next.swiper-button-disabled {
          opacity: 0.4;
        }
      }
    }

    .swiper-scrollbar {
      margin: 24px 0;
    }

    &.full-width {
      &.padding {
        padding: 0;
      }

      & .title-wrap,
      & .scrollbar-wrap {
        margin: 0 15px !important;

        @media (--full-grid-viewport) {
          margin: 0 auto !important;
        }

        h2 {
          padding-left: 0;
          padding-right: 0;
        }
      }

      & .navigation-buttons {
        position: absolute;
        width: 100%;
        left: 0;
        top: calc(var(--slide-item-height-low) / 2 - var(--unit-5) / 2);

        @media (--tablet-viewport) {
          top: calc(var(--slide-item-height-high) / 2 - var(--unit-5) / 2);
        }

        .navigation-buttons-inner {
          justify-content: space-between;
        }

        & .swiper-button-prev,
        & .swiper-button-next {
          background: var(--white);
          margin: 0;

          & svg {
            stroke: var(--blue-darkest);
          }
        }
      }

      & .swiper-container {
        overflow: visible;
      }
    }
  }

  &.m {
    background: none;

    & .swiper-container {
      margin-left: 0;

      @media (--tablet-viewport) {
        width: 66%;
      }

      & .swiper-wrapper {
        margin-bottom: 0;
      }

      & .swiper-slide {
        flex-shrink: 0;
        cursor: grab;

        & img,
        embetty-video {
          width: 100%;
          height: 300px;
          object-fit: contain;
          object-position: center;
          background: var(--gray-light);

          @media (--tablet-viewport) {
            height: 450px;
          }
        }

        embetty-video {
          flex-direction: column;
          justify-content: center;
        }

        & .image-caption {
          position: relative;

          & .button--wrapper {
            padding: 0 !important;
            min-height: initial;

            .text-link {
              margin-top: 0;
            }
          }
        }
      }

      & .navigation-buttons {
        position: absolute;
        left: 0;
        width: 100%;
        top: calc(300px / 2);

        @media (--tablet-viewport) {
          top: calc(var(--unit-5) + 450px / 2);
        }
      }
    }
  }

  &.l {
    background: var(--gray-light);

    & .swiper-slide {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-shrink: 0;
      cursor: grab;

      @media (--tablet-viewport) {
        width: 90%;
        max-width: 980px;
      }

      & img,
      embetty-video {
        height: 280px;
        width: 100%;
        background: var(--white);
        object-fit: contain;

        @media (--tablet-viewport) {
          display: flex;
          height: 550px;
        }
      }

      embetty-video {
        flex-direction: column;
        justify-content: center;
      }

      & .image-caption {
        transition: opacity 0.3s;
        position: relative;
        opacity: 0;
        color: var(--gray);
        font-weight: normal;
      }

      &.swiper-slide-active {
        & .image-caption {
          position: relative;
          opacity: 1;
        }
      }
    }

    & .navigation-buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      left: 0;
      top: calc(280px / 2);

      @media (--tablet-viewport) {
        top: calc(var(--unit-5) + 550px / 2);
      }

      .navigation-buttons-inner {
        width: 100%;
        max-width: 1200px;
      }
    }
  }
}
.code-module {
  display: flex;
  justify-content: center;

  & iframe {
    width: 100%;
    max-width: 500px;
    min-height: 600px;
    border: none;
  }

  &.full {
    & iframe {
      max-width: none;
    }
  }
}

.cookie {
  z-index: 5;
  background: var(--white);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: var(--unit-2);
  padding-bottom: var(--unit-3);
  transition: transform 0.4s ease;
  font-family: var(--font-Family);

  & .container {
    background: var(--gray-lighter);
    margin: 0 1em;
    width: calc(100% - 2em);
    padding-bottom: var(--unit-1);
  }

  @media (--tablet-viewport) {
    padding: 0;

    & .container {
      padding-top: var(--unit-3);
      padding-bottom: var(--unit-3);
      margin: 0;
      width: 100%;
    }
  }

  & .cookie-wrapper {
    align-items: center;

    & .icon {
      width: 100%;
      height: 40px;
    }

    & .cookie-decline,
    & .cookie-accept {
      padding: 5px;
    }

    & button {
      transition: background-color 0.1s ease, color 0.2s ease;
    }

    @media (--tablet-viewport-max) {
      & .cookie-accept,
      & .icon,
      & .text {
        margin-top: var(--unit-3);
      }
    }
  }

  &.set {
    transform: translateY(120%);
  }
}

.download-module {
    & h2 {
        margin-bottom: var(--unit-2);
    }

    & .download-wrapper {
        display: flex;
        align-items: start;

        & .download-item {
            /*width: 100%;*/
            margin: 0 0 var(--unit-4);
            transition: all 0.2s ease;

            & button {
                width: 100%;
                height: 100%;
                flex-direction: column;
                border: solid 2px var(--brand-primary);
                text-transform: none;

                &:hover {
                    background-color: var(--gray-lighter);
                    border-color: var(--white);
                    transition: all 0.2s ease;
                }

                &:hover span {
                    color: var(--brand-primary);
                    transition: all 0.2s ease;
                }

                & .top {
                    width: 100%;
                    display: flex;
                    align-items: center;
                }

                & .preview {
                    margin-top: var(--unit-3);
                }

                @media (--tablet-viewport-max) {
                    justify-content: center;
                }
            }

            & span {
                color: var(--gray-darkest);
            }

            & svg {
                fill: var(--brand-primary);
                stroke: none;
                margin: 0 var(--unit-2) 0 0;
                height: 40px;
                width: 40px;
            }
        }
    }
}
footer {
  width: 100%;
  background-color: var(--gray-darkest);
  color: var(--gray-lightest);
  padding-top: var(--unit-4);
  overflow: hidden;

  &.out {
    transition: transform 0.3s;
  }

  &.in {
    transition: transform 0.3s 0.156s ease-out;
  }

  & li {
    list-style: none;
  }

  & .grid-wrapper {
    width: 100%;
    position: relative;
  }

  & a {
    color: var(--gray-lightest);
    font-weight: 100;
    transition: color 0.2s ease;

    &:hover {
      color: var(--gray-dark);
    }
  }

  & .backtotop-container {
    position: absolute;
    right: 15px;
    z-index: 3;
    width: auto;
    padding: 0;

    & .backtotop {
      width: 50px;
      height: 50px;
      padding: 0;
      margin: 0;
      background-color: var(--gray-lighter);

      & svg {
        width: 30px;
        height: 30px;
        margin: 0;
        margin-top: -5px;
      }
    }
  }

  & .wrapper {
    display: flex;
    flex-direction: row;
    padding-bottom: var(--unit-3);

    & h4 {
      color: var(--gray-lightest);
      padding-bottom: var(--unit-3);
    }

    & .highlightlink-container {
      padding-bottom: var(--unit-3);

      & ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;

        & li {
          padding-bottom: var(--unit-4);
        }

        & li a {
          margin-right: var(--unit-5);
          text-transform: uppercase;

          &:hover {
            color: var(--gray-dark);
          }
        }

        & .last {
          margin-right: 0px;
        }
      }
    }

    & .social-links {
      padding-bottom: var(--unit-4);

      & .items {
        display: flex;
        gap: 15px;

      }

      & h4 {
        padding-bottom: var(--unit-1);
        text-transform: none;
      }

      & svg {
        width: 30px;
        height: 30px;
        fill: var(--white);
        stroke-width: 0;
        transition: fill 0.2s ease;
        cursor: pointer;

        &:hover {
          fill: var(--gray-dark);
        }
      }
    }
  }

  & .end {
    display: flex;
    flex-direction: row;
    overflow: hidden;

    & .museumlink-container {
      & ul {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;

        & li {
          padding-bottom: var(--unit-2);
          max-width: 300px;
        }
      }
    }

    & .furtherlink-container {
      padding-bottom: var(--unit-5);

      & ul {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;

        & li {
          padding-bottom: var(--unit-2);
          max-width: 300px;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  footer .museumlink-container {
    padding-bottom: var(--unit-5);

    & li {
      width: 50%;
      padding-right: 30px;
    }
  }

  footer .furtherlink-container {
    padding-bottom: var(--unit-5);

    & li {
      /* width: calc(33% - 30px); */
      padding-right: 30px;
    }
  }
}

@media (min-width: 1232px) {
  .footer .backtotop-container {
    right: 0px;
  }
}

.employee {
  margin-bottom: var(--unit-5);

  & img {
    width: 100%;
    margin-bottom: var(--unit-2);
  }

  & h4 {
    margin-bottom: var(--unit-1);
  }

  & a {
    color: var(--brand-primary);
    font-weight: 300;
    text-decoration: underline;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  & p {
    margin: 0;
  }

  & .mail {
    margin-bottom: var(--unit-2);
  }
}

.info-module {
  & h1 {
    padding-bottom: var(--unit-1);
  }

  & .table {
    padding: 0;
    margin: 0;
  }

  & .horizontal-container {
    padding: var(--unit-4) 0;
    margin: 0;
    border-top: 1px solid var(--gray-light);
  }

  & .vertical-container {
    border: none;
    padding: 0 30px 0 0;

    & .title {
      padding-bottom: var(--unit-2);
    }

    & .price {
      display: flex;
      flex-direction: row;
      padding-bottom: var(--unit-1);

      & h1 {
        width: fit-content;
        font-size: var(--h1);
        color: var(--brand-primary);
        margin: 0;
        padding: 0;
        margin-right: 10px;
      }

      & h4 {
        color: var(--gray-dark);
        margin: 0;
        padding: 0;
        padding-top: var(--unit-2);
      }
    }

    & .discount {
      width: fit-content;
      color: var(--blue-darkest);
      background: var(--gray-lighter);
      text-align: center;
      padding: 2px 5px;
    }
  }

  & .last {
    padding: 0;
  }

  & button {
    margin-top: var(--unit-2);
  }
}

noscript {
  & .background {
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    opacity: 0.3;
    z-index: 8;
    height: 100vh;
  }

  & .noscript {
    position: fixed;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    height: 40vh;
    z-index: 9;
    background: var(--gray-lighter);
    opacity: 1;

    & .notification {
      display: block;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      margin: auto;
      padding: 5%;

      @media (--desktop-viewport) {
        padding: 10%;
      }

      & p {
        padding-bottom: var(--unit-2);
      }

      & div {
        margin: auto;
        padding: 0;
      }
    }
  }
}

.partnerlist {
  margin-bottom: var(--unit-5);

  & > .row {
    margin: 0;
  }

  & h3 {
    margin-bottom: var(--unit-5);
  }

  & .partner-container {
    margin: auto;

    @media (--small-viewport) {
      margin-left: 0;
      padding-left: 0;
    }

    & .partner {
      margin: auto;

      @media (--small-viewport) {
        margin-left: 0;
        padding-left: 0;
      }

      & .partnerlogo {
        padding: 0;

        & source {
          width: 100%;

          @media (--small-viewport) {
            max-width: 150px;
          }
        }

        & img {
          width: 100%;
          margin-bottom: var(--unit-5);

          @media (--small-viewport) {
            max-width: 150px;
          }
        }
      }
    }
  }
}
.publications {
  & .publication-module {
    margin-bottom: var(--unit-5);

    & .publication-cover {
      padding: 0;
      margin: 0 0 var(--unit-3) 0;
      display: block;
      width: 100%;
      overflow: hidden;

      @media (--small-viewport-max) {
        width: 250px;
        height: 350px;
      }

      & img {
        width: 100%;
        object-fit: cover;
        object-position: 50% 50%;

        @media (--small-viewport-max) {
          width: 250px;
          height: 350px;
        }
      }
    }

    & .publication-information {
      & .kicker {
        color: var(--brand-primary);
        margin-bottom: 4px;
        font-size: 15px;
        hyphens: auto;
        word-break: break-word;
      }

      & .publication-title {
        margin-bottom: 4px;
        font-size: 20px;
        hyphens: auto;
        word-break: break-word;

        /*
        @media (--tablet-viewport) {
          font-size: 26px;
        }
        */

      }

      & .publication-subtitle {
        margin-top: -2px;
        margin-bottom: var(--unit-1);
        font-size: 16px;
        text-transform: initial;
        hyphens: auto;
        word-break: break-word;

        @media (--small-viewport) {
          font-size: 18px;
        }
      }

      & .publication-body {
        margin: 0;

        & .text {
          font-family: var(--font-Family);
          color: var(--gray-darkest);
          font-size: var(--fontsize-normal);
          hyphens: manual;
          margin-bottom: var(--unit-2);
        }

        @media (--small-viewport-max) {
          width: 70%;
        }

        @media (--xsmall-viewport-max) {
          width: 100%;
        }
      }

      & .text-link {
        color: var(--brand-primary);
        font-weight: 500;
        padding: 0 !important;
        margin: 0;
        justify-content: left;

        &:hover {
          color: var(--blue-darker);
        }

        &:hover svg {
          stroke: var(--blue-darker) !important;
          transform: translateX(5px);
        }

        & svg {
          margin: auto 8 auto 0;
          fill: none;
          stroke: var(--brand-primary);
        }
      }
    }
  }
}
button.read-more {
  display: none;
}

.read-more-container {
  & .text-content,
  & .teaser-content {
    transition-property: height;
    transition-timing-function: ease;
    height: auto;
    overflow: hidden;
  }

  & button.read-more {
    display: flex;
    align-items: center;
    margin: 1em 0;
  }

  & button.read-more {
    & svg {
      height: 30px;
      transition: all 0.2 ease;
      transform: rotate(-180deg);
    }

    & .more {
      display: none;
    }
  }

  &.closed:focus-within {
    & .text-content,
    & .teaser-content {
      height: initial !important;

      &.ellipsis {
        display: initial !important;
        -webkit-line-clamp: initial !important;
        -webkit-box-orient: initial !important;
      }

      & a {
        opacity: 1;
      }
    }

    & button.read-more {
      & svg {
        transform: rotate(-180deg);
      }

      & .less {
        display: inline;
      }

      & .more {
        display: none;
      }
    }
  }

  &.closed {
    & .text-content,
    & .teaser-content {
      height: calc((var(--fontsize-normal) * 1.5) * 4) !important;

      &.ellipsis {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }

      & a {
        opacity: 0;
      }
    }

    & button.read-more {
      & svg {
        transform: rotate(0deg);
      }

      & span {
        margin-top: 0;
      }

      & .less {
        display: none;
      }

      & .more {
        display: inline;
      }
    }

    &.text-block {
      & .text-content,
      & .teaser-content {
        margin-bottom: 0;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          height: 50%;
          width: 100%;
          background: linear-gradient(
                  to bottom,
                  rgba(255, 255, 255, 0) 0%,
                  rgba(255, 255, 255, 1) 90%
          );
        }
      }
    }
  }
}
.pricing {

  & h1 {
    padding-bottom: var(--unit-1);
  }

  & .table {
    padding: 0;
    margin: 0;
  }

  & .horizontal-container {
    padding: var(--unit-4) 0;
    margin: 0;
    border-top: 1px solid var(--gray-light);
  }

  & .vertical-container {
    border: none;
    padding: 0 30px 0 0;

    & > p {
      color: var(--gray-darkest);
      padding: 0;
    }

    & .title {
      padding-bottom: var(--unit-2);
      color: var(--gray-darkest);
      padding: 0;
    }

    & .price {
      display: flex;
      flex-direction: row;
      padding-bottom: var(--unit-1);
      padding: 0;

      & h1 {
        width: fit-content;
        font-size: var(--h1);
        color: var(--brand-primary);
        margin: 0;
        padding: 0;
        margin-right: 10px;
      }

      & h4 {
        color: var(--gray-dark);
        margin: 0;
        padding: 0;
        padding-top: var(--unit-2);
      }
    }

    & .discount {
      width: fit-content;
      color: var(--blue-darkest);
      background: var(--gray-lighter);
      text-align: center;
      padding: 2px 5px;
    }
  }

  & .last {
    padding: 0;
  }

  & button {
    margin-top: var(--unit-2);

    & span {
      margin-top: 0;
    }
  }
}

.search-results-main {
  transition: margin 0.3s ease;

  &.scroll {
    & nav {
      top: 3px;

      & .content {
        height: 70px;
      }

      & .searchbar-wrapper .searchbar {
        height: 50px;
      }

      & .logo .text {
        left: -100%;
      }
    }

    & .search-results-content .categories {
      top: 120px;
    }
  }

  &.search-open {
    margin-top: 220px;
  }

  & nav {
    display: none;
    transition: height 0.3s linear, top 0.3s linear;

    &.scroll .content {
      height: 125px;
    }

    @media (--desktop-viewport) {
      display: block;
    }

    & .logo {
      margin-right: var(--unit-5);
      flex-shrink: 0;
    }

    & .divider {
      height: 100%;
      width: 1px;
      flex-shrink: 0;
      background: var(--gray-light);
    }

    & .searchbar-wrapper {
      width: 100%;
      margin-left: var(--unit-5);

      & .searchbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 70px;
        padding: 0 var(--unit-3);
        background: var(--gray-lighter);
        transition: height 0.3s ease;

        & svg {
          width: 30px;
          height: 30px;
          flex-shrink: 0;
        }

        & .search-icon {
          stroke: var(--blue-dark);
        }

        & button {
          padding: 0;
        }

        & .cancel-icon {
          cursor: pointer;
        }

        & input {
          width: 100%;
          margin: 0 var(--unit-3);
          padding: 0;
          font-family: var(--font-Family);
          color: var(--gray);
          outline: none;
          border: none;
          -webkit-appearance: none;
          background: transparent;
        }
      }
    }
  }

  &.in {
    transition: transform 0.3s 0.156s ease-out;
  }

  & .search-results-content {
    display: flex;
    flex-direction: column;
    padding-top: var(--unit-2);

    @media (--desktop-viewport) {
      flex-direction: row;
      padding-top: var(--unit-5);
    }

    & .categories {
      width: 100%;

      flex-shrink: 0;

      @media (--desktop-viewport) {
        position: fixed;
        top: 2.5em;
        width: 230px;
        margin-right: var(--unit-5);
        transition: top 0.3s linear;
      }

      & h4 {
        margin-bottom: 0;
        transition: color 0.2s ease;

        &.active {
          color: var(--brand-primary);
        }

        &:hover {
          color: var(--blue-lighter);
        }
      }

      & button {
        display: none;
        margin: 0;
        margin-bottom: var(--unit-2);
        padding: 0;
        padding: 2px 25px 15px 0 !important;
        min-height: 0;

        &:active {
          background: transparent;
        }

        @media (--desktop-viewport) {
          display: block;
        }
      }

      & select {
        margin-bottom: var(--unit-4);
        background-position: right 10px center;

        @media (--desktop-viewport) {
          display: none;
        }
      }
    }

    & .results {
      width: 100%;

      @media (--desktop-viewport) {
        margin-left: calc(230px + 5em);
      }

      & p {
        text-transform: uppercase;
        margin-bottom: var(--unit-1);
      }

      & h3 {
        display: flex;
        align-items: center;

        & svg {
          margin-bottom: 3px;
        }
      }

      & .result-item {
        display: block;
        margin-bottom: var(--unit-5);
        cursor: pointer;
        transition: opacity 0.2s ease;
        font-weight: 400;

        &.icon svg {
          display: block;
        }

        & .result-description {
          display: flex;
        }

        & picture {
          display: block;
          width: 150px;
          overflow: hidden;
          margin-right: 20px;
          margin-bottom: var(--unit-2);

          & img {
            width: 100%;
            height: auto;
          }
        }

        & svg {
          display: none;
          flex-shrink: 0;
          margin-left: var(--unit-1);
          width: 20px;
          height: 20px;
          margin-bottom: var(--unit-1);

          @media (--small-viewport) {
            width: 25px;
            height: 25px;
            margin-bottom: 0;
          }
        }

        &:hover {
          opacity: 0.85;
        }

        & .result-title {
          @media (--desktop-viewport-max) {
            font-size: 12px;
            margin-bottom: 0.2em;
          }
        }
      }
    }
  }

  & .searchbar-mobile {
    padding-top: var(--unit-2);

    @media (--desktop-viewport) {
      display: none;
    }

    & form {
      display: flex;
      flex-direction: row;
      height: 50px;
      width: 100%;
      background: var(--gray-lighter);
      border: 1px solid #ededed;
    }

    & input {
      width: 100%;
      padding: 0;
      margin: 0 0.75rem;
      font-family: var(--font-Family);
      color: var(--gray);
      outline: none;
      border: none;
      -webkit-appearance: none;
      background-color: transparent;
    }

    @media (--tablet-viewport) {
      display: none;

      & form {
        background: none;
      }

      & input {
        background: red;
      }
    }

    & input::-webkit-search-cancel-button {
      display: none !important;
    }

    & button {
      padding: 0 !important;
    }

    & svg {
      width: 30px;
      height: 30px;
      margin-right: var(--unit-2);
      flex-shrink: 0;
    }
  }
}

.search-page {
  & .desktop-nav-links {
    display: none !important;
  }
}
nav {
  & .content {
    & .desktop-nav-links {
      display: none;
      margin-right: 65px;

      @media (--desktop-viewport) {
        display: block;
      }

      & ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 1em 0.5em 1em 0;
        padding: 0;

        & li.active .nav-item-dropdown,
        & li:focus-within .nav-item-dropdown {
          display: block;

          & .inner {
            visibility: visible;
            opacity: 1;
          }
        }

        & li.active > a::after {
          @media (--desktop-viewport) {
            display: block;
          }
        }

        /* & li:hover .nav-item-dropdown {
          @media (--desktop-viewport) {
            z-index: 1;
            visibility: visible;
            opacity: 1;
            & .inner {
              visibility: visible;
              opacity: 1;
            }
          }
        }
        & li:focus-within .nav-item-dropdown {
          z-index: 1;
          visibility: visible;
          opacity: 1;
          & .inner {
            visibility: visible;
            opacity: 1;
          }
        }
        & li > a:focus + .nav-item-dropdown,
        & li > button:focus + .nav-item-dropdown {
          @media (--desktop-viewport) {
            z-index: 1;
            visibility: visible;
            opacity: 1;
            & .inner {
              visibility: visible;
              opacity: 1;
            }
          }
        }
        & li:hover > a::after {
          @media (--desktop-viewport) {
            display: block;
          }
        } */

        & li.active > a,
        & li > a.current {
          color: var(--brand-primary);
        }

        & li.active svg {
          stroke: var(--brand-primary);
        }

        & li a {
          position: relative;
          display: block;
          margin: var(--unit-2) 0.5em;
          padding: 0.5em;
          text-transform: uppercase;
          color: var(--gray);
          transition: color 0.2s ease;

          &:hover {
            color: var(--brand-primary);
          }

          &::after {
            content: "";
            display: none;
            position: absolute;
            width: 500%;
            height: 130%;
            left: -200%;
            bottom: -130%;
            cursor: auto;
          }
        }

        & li .more svg {
          width: 30px;
          height: 30px;
        }

        & li:hover button::after {
          @media (--desktop-viewport) {
            display: block;
          }
        }

        & li button {
          margin: 0 0.5em;
          padding: 0.5em;

          &::after {
            content: "";
            display: none;
            position: absolute;
            width: 500%;
            height: 130%;
            left: -200%;
            bottom: -100%;
            cursor: auto;
          }
        }

        & .nav-item-dropdown {
          @media (--desktop-viewport) {
            position: fixed;
            top: 158px;
            left: 0;
            width: 100%;
            min-height: 335px;
            background: var(--gray-lighter);
            display: none;

            & .inner {
              margin: var(--unit-5) auto;
              transition: opacity 0.3s ease 0.1s, visibility 0.3s ease 0.1s;
              visibility: hidden;
              opacity: 0;
            }

            & a {
              display: block;
              margin: 0 0 var(--unit-5) 0;
              padding: 0;
              text-transform: uppercase;
              color: var(--gray-darker);
              transition: color 0.2s ease;

              &:hover,
              &.active {
                color: var(--brand-primary);
              }
            }

            & .sub-content-bg {
              height: 100%;
              background: var(--gray-light);
              width: 100%;
              position: absolute;
              top: 0;
              left: 65%;
              z-index: -1;

              @media (--full-grid-viewport) {
                left: calc(65% - 1em);
              }

              @media (--large-viewport) {
                left: calc(61% - 1em);
              }

              @media (--xlarge-viewport) {
                left: calc(58% - 1em);
              }
            }
          }
        }

        & .sub-content {
          background: var(--gray-light);

          & img {
            width: 100%;
          }

          & p,
          & h4 {
            color: var(--gray-darker);
          }

          & p {
            margin-top: var(--unit-2);
            margin-bottom: var(--unit-1);
          }

          & h4 {
            margin: 0;
          }
        }

        & a.more-item {
          @media (--desktop-viewport) {
            display: none;
          }
        }

        & button.more-item {
          display: none;

          @media (--desktop-viewport) {
            display: block;
          }
        }

        & .more-item {
          & svg {
            width: 30px;
            height: 30px;
          }

          & a {
            color: var(--gray);
          }
        }
      }
    }
  }
}
nav {
  & .mobile-nav-links {
    width: calc(100% + 2em);
    margin-left: -1em;
    overflow-y: auto;
    background: white;
    -webkit-overflow-scrolling: touch;
    transition: top 0.3s linear, height 0.3s ease;
    border-top: 1px solid var(--gray-light);

    @media (--desktop-viewport) {
      display: none;
    }

    & .link-container {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;

      & .item {
        &.active {
          & .wrapper {
            & a {
              color: var(--brand-primary);
            }

            & svg {
              transform: rotate(180deg);
            }
          }
        }

        & .wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid var(--gray-light);

          & a {
            width: 100%;
            font-size: 20px;
            padding: 0.75em;
            color: var(--gray);
            text-transform: uppercase;
            border-right: 1px solid var(--gray-light);
            transition: color 0.2s ease;

            &:hover {
              color: var(--brand-primary);
            }
          }

          & .toggle {
            width: 60px;
            height: 60px;
            stroke-width: 2;
            padding: 1em;
            flex-shrink: 0;

            & svg {
              width: 100%;
              height: 100%;
              transition: transform 0.3s ease;
              margin: 0;
            }
          }
        }

        & .mobile-dropdown {
          background: var(--gray-lighter);

          &.show {
            border-bottom: 1px solid var(--gray-light);
          }

          & .inner {
            padding: var(--unit-2) var(--unit-4);

            & a {
              display: block;
              color: var(--gray-darker);
              text-transform: uppercase;
              transition: color 0.2s ease;
              padding: var(--unit-2) 0;

              &.active {
                color: var(--brand-primary);
              }
            }
          }
        }
      }
    }
  }
}
nav {
  position: fixed;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid var(--gray-light);
  background: var(--white);
  z-index: 5;
  transition: top 0.3s linear, height 0.3s linear;

  &.menu-open {
    height: 100%;
    border-bottom: 0;

    & .grid-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  @media (--desktop-viewport) {
    top: 2em;
  }

  & li {
    list-style: none;
  }

  & .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 70px;
    width: 100%;
    align-items: flex-start;
    background: white;
    transition: height 0.3s linear;

    @media (--desktop-viewport) {
      position: relative;
      height: 125px;
      align-items: center;
      background: none;
    }

    &.menu-open {
      height: fit-content;

      & .logo {
        display: none;
      }
    }

    & .right-section {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-end;
      align-items: flex-start;
      max-height: 70px;

      @media (--desktop-viewport) {
        position: relative;
        height: 100%;
        align-items: center;
        max-height: none;
      }

      & button:active {
        background: transparent;
      }
    }

    & .logo {
      position: relative;
      overflow: hidden;
      width: 185px;
      overflow: visible;
      align-self: center;
      flex-shrink: 0;

      @media (--desktop-viewport) {
        width: 250px;
      }

      & .logo-container {
        position: absolute;
        z-index: 1;
        padding: var(--unit-1);
        border-left: 3px solid var(--brand-primary);
        transform: translateY(-18px);
        transition: all 0.3s ease-in;

        @media (--desktop-viewport) {
          transform: translateY(-24px);
        }

        & .fws-logo {
          transition: all 0.3s ease-in;
          height: 24px;
          width: 100px;

          @media (--desktop-viewport) {
            height: 36px;
            width: 150px;
          }
        }

        & .siegert-logo {
          transition: all 0.3s ease-in;
          height: 24px;
          width: 150px;

          @media (--desktop-viewport) {
            height: 36px;
            width: 250px;
          }
        }
      }

      & svg {
        stroke-width: 0;
      }

      & .symbol {
        height: 24px;
        width: 19px;

        @media (--desktop-viewport) {
          height: 29px;
          width: 23px;
        }
      }

      & .text {
        position: absolute;
        z-index: 1;
        transition: all 0.3s ease-in;
        height: 13px;
        width: 158px;
        left: 27px;

        @media (--desktop-viewport) {
          height: 16px;
          width: 161px;
          left: 33px;
        }
      }
    }

    & .navbar-toggler {
      margin-left: var(--unit-1);
      border: none;
      border-radius: 0;
      padding: 0;
      height: 70px;

      @media (--desktop-viewport) {
        display: none;
        margin-left: var(--unit-2);
        height: auto;
      }

      &.collapsed {
        & .close {
          display: none;
        }

        & .menu {
          display: block;
        }
      }

      & .menu {
        display: none;
      }

      & svg {
        opacity: 1 !important;
        margin-left: 0;
      }

      @media (--desktop-viewport) {
        display: none;
      }
    }

    & .search-container {
      display: flex;
      align-items: center;
      height: 100%;
      margin-top: 10px;

      @media (--desktop-viewport) {
        position: absolute;
        left: calc(100% - 60px);
        width: 100%;
        margin-top: 0;
        background: #fff;
        border-left: 1px solid var(--gray-light);
        transition: transform 0.45s ease;
      }

      &.search-open {
        & button.toggleSearch {
          @media (--desktop-viewport) {
            padding: var(--unit-1) 0 var(--unit-1) 42px;
          }

          & svg {
            stroke: var(--brand-primary);
          }
        }
      }

      &.no-animation {
        transition: none !important;

        & button,
        & .searchbar-outer,
        & .searchbar-outer .hidden {
          transition: none !important;
        }
      }

      & button {
        height: 50px;
        z-index: 2;
        transition: all 0.3s ease-out;

        @media (--desktop-viewport) {
          padding: var(--unit-1) 0 var(--unit-1) 30px;
        }

        & svg {
          margin: 0;
          width: 28px;
          height: 28px;
        }
      }

      & svg {
        stroke-width: 2;

        &:hover {
          stroke: var(--brand-primary);
        }
      }

      & .searchbar-outer {
        position: absolute;
        width: stretch;
        display: none;
        align-items: center;

        /* mobile only */
        display: flex;
        left: 0;
        top: 71px;
        max-height: 250px;
        margin: 0;
        padding: 0;
        background: var(--white);
        z-index: -1;
        border-bottom: 1px solid var(--gray-light);
        transition: top 0.3s ease, opacity 0.3s ease, max-height 0.2s ease;

        &.hidden {
          top: 0;
          opacity: 0;
          max-height: 70px;
          overflow: hidden;
        }

        @media (--desktop-viewport) {
          display: flex;
          top: 0;
          left: 0;
          height: 100%;
          max-height: none;
          z-index: unset;
          background: none;
          border-bottom: none;
          transition: opacity 0.35s ease, visibility 0.35s ease;

          &.hidden {
            top: 0;
            opacity: 0;
            max-height: none;
            visibility: hidden;
          }
        }

        & #searchbarDynamic {
          display: none;
        }

        & #searchbar,
        & #searchbarDynamic {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          width: 100%;
          flex-direction: column;
          transition: height 0.3s linear;
          padding: var(--unit-2) var(--unit-2) 0;

          @media (--desktop-viewport) {
            padding: 0 0 0 var(--unit-3);
            height: 70px;
            flex-direction: row;

            & button[type='submit'] {
              width: auto;
            }
          }

          & svg {
            width: 30px;
            height: 30px;
            flex-shrink: 0;
          }

          & .search-icon {
            stroke: var(--blue-dark);
          }

          & button {
            padding: 0;

            &[type='submit'] {
              padding: 0 var(--unit-2);
              align-items: center;
              height: 50px;
              margin: 1em 0;
              transition: background 0.2s ease, color 0.2s ease;

              @media (--desktop-viewport) {
                margin: 0 0 0 var(--unit-3);
                height: 100%;
              }

              &[disabled] {
                background: var(--gray-lighter);
                color: var(--gray-darker);
              }

              &:active {
                background: var(--blue-darkest);
              }
            }
          }

          & .input-bar {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            background: var(--gray-lighter);
            border: 1px solid var(--gray-light);
            padding-right: 0.7em;

            @media (--desktop-viewport) {
              background: transparent;
              border: none;
              padding-right: 0;
            }

            & input {
              font-family: var(--font-Family);
              color: var(--gray);
              outline: none;
              border: none;
              -webkit-appearance: none;
              width: 100%;
              padding: 0 1em;
              margin: 0;
              background: none;
            }

            @media (--desktop-viewport) {
              padding: 0 1em 0 4em;
              background: var(--gray-lighter);

              & input {
                background: none;
                padding: 0;
                margin: 0;
              }
            }

            & .cancel-icon {
              cursor: pointer;
              transition: opacity 0.2s ease;

              &:hover {
                opacity: 0.8;

                & svg {
                  stroke: var(--gray);
                }
              }
            }
          }

          & input::-webkit-search-cancel-button {
            display: none !important;
          }
        }
      }
    }
  }
}

nav.scroll {
  @media (--desktop-viewport) {
    top: 0;

    & .content {
      height: 70px;

      & .desktop-nav-links .nav-item-dropdown,
      & .desktop-nav-links .dropdown-helper {
        top: 71px;
      }

      & .search-container {
        & .searchbar-outer {
          & #searchbar,
          & #searchbarDynamic {
            height: 50px;
          }
        }
      }

      & .toggleSearch {
        max-height: 60px;
      }
    }

    & .logo .text {
      opacity: 0;
      transform: translateY(5px);
    }

    & .logo .logo-container {
      transform: translateY(-18px);

      & .fws-logo {
        height: 24px;
        width: 100px;
      }

      & .siegert-logo {
        height: 24px;
        width: 250px;
      }
    }
  }
}
.text-block {
  & .title-content {
    margin-bottom: var(--unit-4);

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  & .text-content {
    margin-bottom: var(--unit-4);

    @media (--tablet-viewport-max) {
      column-count: 1 !important;
    }

    & a {
      & button {
        text-decoration: none;
        width: fit-content;
      }
    }
  }

  & .adjust-offset-padding {
    flex: 0 0 100%;
    max-width: 100%;

    @media (--small-viewport) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  @media (--tablet-viewport) {
    & .adjust-offset-padding {
      padding-left: 5px;
    }
  }

  &.blog {

    & .text-content {
      margin-top: 0;
    }
  }
}
.text-image {
  margin: 0;
  padding: 0;

  & .image-container {
    padding: 0 0 var(--unit-2) 0;
  }

  & .text-container {
    padding: 0;

    @media (--full-grid-viewport) {
      padding: 0 0 0 var(--unit-4);
    }
    & .kicker {
      margin-bottom: 0.5em;
      color: var(--brand-primary);
    }
  }
}
.video {
  & h1 {
    margin-bottom: var(--unit-1);
  }

  & .video-wrapper {
    position: relative;
    padding-bottom: 56%;
    overflow: hidden;
    max-width: 100%;
    background: black;
  }

  & .video-wrapper embetty-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.calendar-inner {
  & .type {
    background: var(--gray-lighter);
    width: fit-content;
    padding: 0.2em 0.5em;
    color: var(--blue-darkest);
    margin-bottom: var(--unit-1);
  }

  & .dates {
    & .date-wrapper {
      padding-top: 2em;
      border-top: 2px solid var(--brand-primary);

      &:focus {
        outline: -webkit-focus-ring-color auto 1px;
      }

      & .date {
        display: flex;
        align-items: flex-start;
        margin-bottom: var(--unit-4);

        .row {
          padding-left: var(--unit-1-rem);
          padding-right: var(--unit-1-rem);
        }

        .row > * {
          width: auto;
        }

        & h1  {
          color: var(--brand-primary);
          margin: 0;
          padding: 0;
          margin-right: var(--unit-1);
          line-height: 1;

          @media (--full-grid-viewport) {
            margin-left: var(--unit-2-rem);
          }
        }

        & h3  {
          margin: 0;
          padding: 0;
          color: var(--gray-dark);
        }
      }

      & .entry {
        border-bottom: 1px solid var(--gray-light);
        margin-bottom: 2em;
        margin-left: 0;
        margin-right: 0;

        &.placeholder {
          &:hover {
            cursor: default;
          }

          & .body {
            & h4,
            & p {
              display: none;
            }
          }

          & .other.container {
            display: none;
          }
        }

        &:focus {
          outline: -webkit-focus-ring-color auto 1px;
        }

        &:hover {
          cursor: pointer;
        }

        &:hover svg {
          stroke: var(--gray-darkest) !important;
          transform: translateX(5px);
        }

        &:hover h3 {
          color: var(--gray-darkest) !important;
        }

        & > div {
          padding: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }

        & .body {
          padding-bottom: var(--unit-2);

          @media (--tablet-viewport) {
            padding-bottom: var(--unit-4);
          }

          & p {
            color: var(--gray-darker);
            margin-bottom: 0;
          }

          & .kicker {
            color: var(--brand-primary);
            margin-bottom: var(--unit-1);
            font-weight: 700;
            text-transform: uppercase;
          }
        }

        & .other {
          padding-bottom: var(--unit-4);

          @media (--tablet-viewport) {
            display: flex;
            justify-content: flex-end;
          }

          @media (--full-grid-viewport) {
            padding-right: 15px;
          }

          & .wrapper {
            padding: 0;

            @media (--tablet-viewport) {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
            }

            & .audiance > div {
              background: #f6f7f8;
              width: -webkit-fit-content;
              width: -moz-fit-content;
              width: fit-content;
              padding: 0.25em 0.5em;
              color: #005480;
              margin-bottom: 0.5em;
            }
          }

          & .button {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding: 0;
          }

          & p  {
            color: var(--blue-dark);
            margin-bottom: 0;

            @media (--tablet-viewport) {
              text-align: right;
            }
          }

          & button {
            background: none;
            padding: 0 !important;
            justify-content: flex-end;

            & h3 {
              color: var(--gray-dark);
              margin: 0;
              padding-top: 4px;
            }

            & svg {
              fill: none;
              stroke: var(--gray-dark);
              margin-top: auto;
              margin-bottom: auto;
            }
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }

      &:last-child .entry:last-child {
        margin-bottom: 2em;
      }
    }
  }
}

.calendar-module {
  & .lazy-load-button {
    &:hover {
      & svg {
        transform: none;
      }
    }
  }

  & .no-result {
    margin: var(--unit-2) 0;

    & p {
      text-transform: uppercase;
    }
  }

  & .loading {
    display: flex;
    justify-content: center;

    & .spinner-border {
      color: var(--brand-primary);
      margin: var(--unit-4) 0;
    }
  }

  & .week-outer {
    @media (--full-grid-viewport) {
      padding: 0;
    }

    & .week {
      background: var(--gray-lighter);
      color: var(--gray);
      font-weight: bold;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 4px 0;
    }
  }
}

.calendar-module {
  overflow: hidden;
  min-height: 100vh;

  .calendar-entries {
    opacity: 0 !important;
    transform: translate3d(0, 2vh, 0);
  }

  &.scroll-animation {
    opacity: 1 !important;
    transform: translate3d(0, 0, 0) !important;
  }

  &.in-viewport {
    & .datepicker .grid-wrapper {
      transition: opacity 1s ease, transform 1.2s ease;
      opacity: 1;
      transform: translateY(0);
    }
  }

  & .grid-wrapper {
    transition: none;
    opacity: 0;
    transform: translate3d(0, 2vh, 0);

    &.in-viewport {
      transition: opacity 1s ease, transform 1.2s ease !important;
      opacity: 1 !important;
      transform: translateY(0) !important;
    }
  }
}

.calendar-single {
  & .button-wrapper {
    @media (--full-grid-viewport) {
      padding: 0 0.5em;
    }
  }
}
.calendar-modal {
  @media screen and (--small-viewport) {
    & .modal-lg,
    & .modal-xl {
      max-width: 768px;
    }
  }

  @media screen and (--desktop-viewport) {
    & .modal-lg,
    & .modal-xl {
      max-width: 945px;
    }
  }

  @media screen {
    & .modal-content {
      border-radius: 0;
      border: none;
      border-top: 4px solid var(--brand-primary);
      padding-top: var(--unit-2);

      @media (--tablet-viewport) {
        border-top: 0;
      }

      & .popup-inner {
        width: 100%;
        margin: 0;

        @media (--tablet-viewport) {
          flex-wrap: nowrap;
        }
      }

      & .modal-body {
        padding-top: 0.5em;

        & .container {
          position: relative;

          & p {
            color: var(--gray-darker);

            & strong {
              color: var(--gray-darkest);
            }
          }

          & button.close {
            position: absolute;
            top: 0;
            right: 0;
            opacity: 1;
            z-index: 1;

            & svg {
              stroke: var(--gray-darker);
              width: 30px;
              height: 30px;
            }
          }

          & > div:last-of-type.calendar-modal-close {
            padding: 1em 0;

            @media (--tablet-viewport) {
              padding: 1em;
            }
          }

          & .modal-content-wrapper {
            margin-top: var(--unit-1);
            padding: 0;

            @media (--tablet-viewport) {
              margin-top: 0;
              max-height: 500px;
              overflow: auto;
            }

            & .kicker {
              font-weight: 700;
              color: var(--brand-primary);
            }

            & .audiance {
              display: flex;
              font-family: var(--font-Family);
              & > div {
                margin-right: var(--unit-1);

                background: #f6f7f8;
                width: -webkit-fit-content;
                width: -moz-fit-content;
                width: fit-content;
                padding: 0.25em 0.5em;
                color: #005480;
                margin-bottom: 0.5em;
              }
            }

            & .availability {
              color: var(--blue-darker);
              margin-bottom: var(--unit-3);
            }

            & .location-time {
              margin-bottom: var(--unit-2);
              & p {
                margin: 0;
              }
            }

            & .description {
              font-family: var(--font-Family);
              color: #54575f;
            }

            & h4 {
              margin-bottom: var(--unit-3);
            }

            & h2 {
              font-size: var(--mobile-h1);
              padding-right: var(--unit-3);

              @media (--tablet-viewport) {
                margin-bottom: 0.2em;
                font-size: var(--h2);
                padding-right: 0;
              }
            }
          }

          & .modal-option-buttons {
            width: 100%;

            & a {
              font-family: var(--font-family) !important;
            }

            & .button-tertiary {
              width: 100%;
              justify-content: flex-start;
            }

            & .print-icon {
              stroke-width: 0.3;
            }

            @media (--tablet-viewport) {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-top: 10%;
              padding: 0 15px;
            }

            & .addeventatc {
              box-shadow: none !important;
              border: none !important;
              padding: 0 !important;
              margin: 0 !important;
              transition: all 0.3s ease;
              display: grid;
              visibility: hidden;
              outline: none;

              & .button-tertiary {
                margin-top: 0;
              }
            }

            & .addeventatc_dropdown {
              transition: all 0.35s ease;
              height: 0;
              overflow: hidden;
              position: relative;
              top: 0 !important;
              left: 0 !important;
              display: block !important;
              outline: none !important;
              opacity: 1;
              box-shadow: none;
              padding: 0;

              & span {
                display: block;
                outline: none;
                cursor: pointer;
              }

              &.addEventShow {
                transition: all 0.35s ease;
                height: 280px;
              }

              & .copyx {
                display: none;
              }
            }

            & .addeventatc_dropdown span {
              color: var(--brand-primary);
              font-family: var(--font-Family);
              font-size: 16px;
              font-weight: 700;
              text-transform: none;
              margin-left: 30px;
              padding: 0.7em 0 0.7em var(--unit-5);
              background-position: 5px 40%;
              background-size: 20px;
            }

            & .collapsable button {
              color: var(--brand-primary);
              font-family: var(--font-Family);
              font-size: 16px;
              font-weight: bold;
              margin-left: 20px;
              text-transform: none;
              margin: 0 0 0 var(--unit-4);
              padding: var(--unit-1) 0;

              & svg {
                fill: var(--brand-primary);
                stroke: none;
                width: 25px;
                height: 25px;
                margin-right: 0.7em;
              }
            }
          }
        }
      }

      @media (--tablet-viewport) {
        margin: 1.75rem auto;
        padding: var(--unit-1);

        & .modal-body {
          padding: var(--unit-4);
        }

        & .modal-content {
          border-radius: 0.3rem;
          border: 0;
          padding: 0;
        }

        & .calendar-modal-close {
          display: none;
        }
      }
    }
  }
}

.calendar-modal.initial-popup {
  & .calendar-modal-close {
    display: block !important;

    @media (--tablet-viewport) {
      padding: 0.5em 0 0 !important;
      max-width: 450px;
    }
  }
}

@media print {
  * {
    -webkit-transition: none !important;
    transition: none !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  .calendar-modal {
    padding: 0 !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: block;

    & .modal-lg,
    & .modal-xl {
      max-width: none !important;
      margin: 0 !important;
      height: 100% !important;
    }

    & .modal-dialog-centered:before {
      display: none;
    }

    & .modal-content {
      position: absolute !important;
      margin: 0 !important;
      height: 100% !important;
      display: block;
      border-radius: 0;
      border: none;
    }

    & .modal-body {
      padding: 2rem;
      display: block;

      & .row {
        display: block;
        margin: 0;
      }
    }

    & .modal-content-wrapper {
      max-height: none;
      max-width: none;
      flex: 0 0 100%;
    }

    & .modal-option-buttons,
    & .modal-body > .container > .close,
    & .calendar-modal-close {
      display: none !important;
    }
  }
}
.datepicker {
  position: relative;
  z-index: 1;
  background: var(--gray-lighter);

  & h2 {
    font-size: var(--h2);
  }

  & h3 {
    font-size: var(--h3);
  }

  & h4 {
    font-size: var(--h4);
  }

  & .month-wrapper {
    display: flex;
    align-items: center;

    @media (--tablet-viewport) {
      padding-bottom: var(--unit-4);
    }

    & .select-wrapper {
      margin: var(--unit-2) 0 var(--unit-5);

      & select {
        width: 100%;
        margin: var(--unit-2) 0 0;

        @media (--tablet-viewport) {
          margin-left: 0;
        }
      }

      @media (--tablet-viewport) {
        margin: 0 16px 0 0;
      }

      @media (--full-grid-viewport) {
        margin: 0;
      }

      & > .col-12.col-md-6 {
        @media (--tablet-viewport-max) {
          margin-bottom: 15px;
        }
      }

      & > .col-12.col-md-6:last-of-type {
        @media (--tablet-viewport-max) {
          margin-bottom: 0;
        }
      }

      & > .col-12:last-of-type {
        @media (--tablet-viewport) {
          padding-right: 0;
        }
      }
    }

    & .dayslider {
      @media (--desktop-viewport) {
        margin-top: var(--unit-3);
      }
    }
  }

  & svg {
    height: 20px;
  }

  & .month-slider-wrapper {
    max-width: 280px;
    margin: 0 auto;

    @media (--tablet-viewport) {
      margin: 0;
    }
  }

  & .slider {
    position: relative;
    margin: var(--unit-2) 0 0;
    height: 70px;

    & button.icon-button {
      position: absolute;
      top: 0;
      padding: 19px 25px !important;

      &.prev {
        left: 0;

        @media (--small-viewport) {
          padding-left: 16px !important;
        }

        @media (--full-grid-viewport) {
          padding-left: 0 !important;
        }
      }

      &.next {
        right: 0;

        @media (--small-viewport) {
          padding-right: 16px !important;
        }

        @media (--full-grid-viewport) {
          padding-right: 0 !important;
        }
      }

      & svg {
        margin: 0;
        width: 18px;
        height: 30px;
      }
    }

    & .slide-wrapper {
      overflow: hidden;
      height: 100%;
      display: block;
      position: relative;
      margin: 0 4em;

      @media (--full-grid-viewport) {
        margin: 0 2.5em;
      }

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
        width: 3%;
        min-width: 10px;
        z-index: 1;
      }

      &:before {
        left: 0;
        background: linear-gradient(
                to right,
                rgba(246, 247, 248, 1) 10%,
                rgb(246, 247, 248, 0)
        );
      }

      &:after {
        right: 0;
        background: linear-gradient(
                to left,
                rgba(246, 247, 248, 1) 10%,
                rgb(246, 247, 248, 0)
        );
      }

      & .left,
      & .active,
      & .right {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & .animate {
        transition: transform 0.5s;

        @media (--small-viewport) {
          transition: transform 1s;
        }
      }

      & .left {
        transform: translateX(-100%);
      }

      & .active {
        transform: translateX(0);
      }

      & .right {
        transform: translateX(100%);
      }

      & h4,
      & h3 {
        margin-bottom: 0;
      }

      & .days-block {
        display: flex;
        width: fit-content;
        margin: 0 auto;

        & button.day-button {
          padding: var(--unit-2) !important;

          @media (--small-viewport) {
            padding: 0 var(--unit-3) !important;
          }

          & .day {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 40px;

            & h2,
            & h4 {
              color: var(--gray-dark);
            }
          }

          &.selected h2,
          &.selected h4 {
            color: var(--gray-darkest);
          }
        }
      }
    }
  }
}
.calendar-teaser {
  & .title {
    margin-bottom: 1em;
  }
}

/*! PhotoSwipe main CSS by Dmytro Semenov | photoswipe.com */

.pswp {
  --pswp-bg: #000;
  --pswp-placeholder-bg: #222;

  --pswp-root-z-index: 100000;

  --pswp-preloader-color: rgba(79, 79, 79, 0.4);
  --pswp-preloader-color-secondary: rgba(255, 255, 255, 0.9);

  /* defined via js:
  --pswp-transition-duration: 333ms; */

  --pswp-icon-color: #fff;
  --pswp-icon-color-secondary: #4f4f4f;
  --pswp-icon-stroke-color: #4f4f4f;
  --pswp-icon-stroke-width: 2px;

  --pswp-error-text-color: var(--pswp-icon-color);
}

/*
	Styles for basic PhotoSwipe (pswp) functionality (sliding area, open/close transitions)
*/

.pswp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--pswp-root-z-index);
  display: none;
  touch-action: none;
  outline: 0;
  opacity: 0.003;
  contain: layout style size;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Prevents focus outline on the root element,
  (it may be focused initially) */
.pswp:focus {
  outline: 0;
}

.pswp * {
  box-sizing: border-box;
}

.pswp img {
  max-width: none;
}

.pswp--open {
  display: block;
}

.pswp,
.pswp__bg {
  transform: translateZ(0);
  will-change: opacity;
}

.pswp__bg {
  opacity: 0.005;
  background: var(--pswp-bg);
}

.pswp,
.pswp__scroll-wrap {
  overflow: hidden;
}

.pswp__scroll-wrap,
.pswp__bg,
.pswp__container,
.pswp__item,
.pswp__content,
.pswp__img,
.pswp__zoom-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pswp__img,
.pswp__zoom-wrap {
  width: auto;
  height: auto;
}

.pswp--click-to-zoom.pswp--zoom-allowed .pswp__img {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.pswp--click-to-zoom.pswp--zoomed-in .pswp__img {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.pswp--click-to-zoom.pswp--zoomed-in .pswp__img:active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

/* :active to override grabbing cursor */
.pswp--no-mouse-drag.pswp--zoomed-in .pswp__img,
.pswp--no-mouse-drag.pswp--zoomed-in .pswp__img:active,
.pswp__img {
  cursor: -webkit-zoom-out;
  cursor: -moz-zoom-out;
  cursor: zoom-out;
}

/* Prevent selection and tap highlights */
.pswp__container,
.pswp__img,
.pswp__button,
.pswp__counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pswp__item {
  /* z-index for fade transition */
  z-index: 1;
  overflow: hidden;
}

.pswp__hidden {
  display: none !important;
}

/* Allow to click through pswp__content element, but not its children */
.pswp__content {
  pointer-events: none;
}
.pswp__content > * {
  pointer-events: auto;
}

/*

  PhotoSwipe UI

*/

/*
	Error message appears when image is not loaded
	(JS option errorMsg controls markup)
*/
.pswp__error-msg-container {
  display: grid;
}
.pswp__error-msg {
  margin: auto;
  font-size: 1em;
  line-height: 1;
  color: var(--pswp-error-text-color);
}

/*
class pswp__hide-on-close is applied to elements that
should hide (for example fade out) when PhotoSwipe is closed
and show (for example fade in) when PhotoSwipe is opened
 */
.pswp .pswp__hide-on-close {
  opacity: 0.005;
  will-change: opacity;
  transition: opacity var(--pswp-transition-duration)
  cubic-bezier(0.4, 0, 0.22, 1);
  z-index: 10; /* always overlap slide content */
  pointer-events: none; /* hidden elements should not be clickable */
}

/* class pswp--ui-visible is added when opening or closing transition starts */
.pswp--ui-visible .pswp__hide-on-close {
  opacity: 1;
  pointer-events: auto;
}

/* <button> styles, including css reset */
.pswp__button {
  position: relative;
  display: block;
  width: 50px;
  height: 60px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  cursor: pointer;
  background: none;
  border: 0;
  box-shadow: none;
  opacity: 0.85;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
}

.pswp__button:hover,
.pswp__button:active,
.pswp__button:focus {
  transition: none;
  padding: 0;
  background: none;
  border: 0;
  box-shadow: none;
  opacity: 1;
}

.pswp__button:disabled {
  opacity: 0.3;
  cursor: auto;
}

.pswp__icn {
  fill: var(--pswp-icon-color);
  color: var(--pswp-icon-color-secondary);
}

.pswp__icn {
  position: absolute;
  top: 14px;
  left: 9px;
  width: 32px;
  height: 32px;
  overflow: hidden;
  pointer-events: none;
  stroke: initial;
  stroke-width: initial;
}

.pswp__icn-shadow {
  stroke: var(--pswp-icon-stroke-color);
  stroke-width: var(--pswp-icon-stroke-width);
  fill: none;
}

.pswp__icn:focus {
  outline: 0;
}

/*
	div element that matches size of large image,
	large image loads on top of it,
	used when msrc is not provided
*/
div.pswp__img--placeholder,
.pswp__img--with-bg {
  background: var(--pswp-placeholder-bg);
}

.pswp__top-bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 10;

  /* allow events to pass through top bar itself */
  pointer-events: none !important;
}
.pswp__top-bar > * {
  pointer-events: auto;
  /* this makes transition significantly more smooth,
     even though inner elements are not animated */
  will-change: opacity;
}

/*

  Close button

*/
.pswp__button--close {
  margin-right: 6px;
}

/*

  Arrow buttons

*/
.pswp__button--arrow {
  position: absolute;
  top: 0;
  width: 75px;
  height: 100px;
  top: 50%;
  margin-top: -50px;
}

.pswp__button--arrow:disabled {
  display: none;
  cursor: default;
}

.pswp__button--arrow .pswp__icn {
  top: 50%;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  background: none;
  border-radius: 0;
}

.pswp--one-slide .pswp__button--arrow {
  display: none;
}

/* hide arrows on touch screens */
.pswp--touch .pswp__button--arrow {
  visibility: hidden;
}

/* show arrows only after mouse was used */
.pswp--has_mouse .pswp__button--arrow {
  visibility: visible;
}

.pswp__button--arrow--prev {
  right: auto;
  left: 0px;
}

.pswp__button--arrow--next {
  right: 0px;
}
.pswp__button--arrow--next .pswp__icn {
  left: auto;
  right: 14px;
  /* flip horizontally */
  transform: scale(-1, 1);
}

/*

  Zoom button

*/
.pswp__button--zoom {
  display: none;
}

.pswp--zoom-allowed .pswp__button--zoom {
  display: block;
}

/* "+" => "-" */
.pswp--zoomed-in .pswp__zoom-icn-bar-v {
  display: none;
}

/*

  Loading indicator

*/
.pswp__preloader {
  position: relative;
  overflow: hidden;
  width: 50px;
  height: 60px;
  margin-right: auto;

  @media (--small-viewport-max) {
    display: none;
  }
}

.pswp__preloader .pswp__icn {
  opacity: 0;
  transition: opacity 0.2s linear;
  animation: pswp-clockwise 600ms linear infinite;
}

.pswp__preloader--active .pswp__icn {
  opacity: 0.85;
}

@keyframes pswp-clockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*

  "1 of 10" counter

*/
.pswp__counter {
  height: 30px;
  margin-top: 15px;
  margin-inline-start: 20px;
  font-size: 14px;
  line-height: 30px;
  color: var(--pswp-icon-color);
  text-shadow: 1px 1px 3px var(--pswp-icon-color-secondary);
  opacity: 0.85;

  @media (--small-viewport-max) {
    display: none;
  }
}

.pswp--one-slide .pswp__counter {
  display: none;
}

/* custom caption */

.pswp__custom-caption {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
}

.pswp__custom-caption span {
  display: block;
  padding: 15px 10px 10px;
  max-width: 100ch;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-light);
  font-family: var(--font-Family);
}

.pswp__custom-caption a {
  color: #fff;
  text-decoration: underline;
  text-transform: uppercase;
}

/* custom sharing */

.pswp__button--share-button {
  position: relative;
  overflow: visible;
}

.pswp__button--share-button .pswp__icn {
  margin-left: 2px;
}

.pswp__button--share-button .pswp-share-options {
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateX(-25%);
  background: white;
  border-radius: 3px;
  overflow: hidden;
}

.pswp__button--share-button .pswp-share-options a {
  display: block;
  text-transform: none;
  padding: 10px 20px;
  transition: color 0.2s ease, background 0.2s ease;
}

.pswp__button--share-button .pswp-share-options a:hover,
.pswp__button--share-button .pswp-share-options a:focus {
  background: var(--gray-light);
}

/* external link */
.pswp__button--external-link {
  width: auto;
  margin-right: 15px;

  @media (--small-viewport-max) {
    margin-right: 5px;
  }
}

.pswp__button--external-link .pswp-external-link {
  position: relative;
  display: block;
  color: var(--white);
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 3px;
  padding-right: 25px;

  @media (--small-viewport-max) {
    padding-right: 22px;
  }
}

.pswp__button--external-link .pswp-external-link:after {
  content: url(../img/svg/extern_link_white.svg);
  position: absolute;
  right: 0;
}

/* Video embed */

.pswp__video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  & iframe {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
    aspect-ratio: 16/9;

    @media (--tablet-viewport) {
      width: 80vw;
    }

    @media (--large-viewport) {
      width: 65vw;
      max-width: 1920px;
    }
  }

  & .video-loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: absolute;
    top: calc(50% -24px);
    left: calc(50% -24px);
    animation: rotate 1s linear infinite;
  }

  & .video-loader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #fff;
    animation: prixClipFix 2s linear infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
.blog-detail-hero {

  & figure {
    display: block;
    margin-bottom: var(--unit-4);

    @media (--small-viewport-max) {
      margin-bottom: var(--unit-3);
    }

    & img {
      width: 100%;
    }

    & figcaption {
      color: var(--gray-darkest);
      padding: var(--unit-2) 0 0 0;
    }
  }

  & .trans {
    & .date-author {
      & p {
        margin-bottom: var(--unit-1);
      }
    }

    & .subtitle {
      margin-bottom: var(--unit-4);

      @media (--small-viewport-max) {
        margin-top: var(--unit-1);
        margin-bottom: var(--unit-3);
      }
    }
  }

  & .subtitle {
    @media (--small-viewport-max) {
      margin-top: var(--unit-2);
      margin-bottom: var(--unit-1);
    }
  }
}

.revoke-consent {
  cursor: pointer;
  color: var(--brand-primary);
  font-family: var(--font-Family);
  font-weight: normal;
  text-decoration: underline;
  display: inline;

  &:hover {
    color: var(--blue-darker);
    text-decoration: underline;
  }
}

hr {
  border: 0.5px solid var(--brand-primary);
  margin-bottom: var(--unit-3);
}

h4 {
  margin-bottom: var(--unit-2);
}

p {
  margin-bottom: var(--unit-2);
}

.share {
  & h3 {
    padding-bottom: var(--unit-1);
  }

  & svg {
    width: 30px;
    height: 30px;
    margin-right: var(--unit-4);
    stroke: none;
    fill: var(--blue-dark);
    cursor: pointer;
    transition: fill 0.2s ease;

    &:hover {
      fill: var(--blue-lighter);
    }
  }
}

.tag {
  & h3 {
    padding-bottom: var(--unit-1);
  }

  & div {
    padding-left: 15px;

    & h4 {
      margin-right: var(--unit-4);
      margin-bottom: var(--unit-3);

      & a {
        color: var(--gray-dark);
        font-weight: 500;

        &:hover {
          color: var(--gray-darkest);
        }
      }
    }
  }
}

.more-articles {
  & h2 {
    margin-bottom: var(--unit-3);
  }

  & section.teaser {
    padding: 0;
  }
}

.generic-form {

  color: #54575f;
  font-family: var(--font-Family);

  label {
    margin-top: .5em;
  }

  textarea {
    height: 150px;
  }

  form {
    div,
    h3 {
      margin-top: var(--unit-2);
      margin-bottom: var(--unit-2);
    }

    .col-md-6 {
      float: left;
      padding-right: 16px;
    }

    .col-md-6.width-is-last {
      float: left;
      clear: right;
    }

    div.nice-select {
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
      font-size: 16px;
    }

    .honung {
      display: none;
    }

    .c-dropdown .dropdown-button {
      top: 5px;
    }
    .c-button--dropdown:hover {
      background-color: #e6e9ed;
    }
    .dropdown-button:not(:disabled) {
      cursor: pointer;
    }
    .dropdown-button:focus, .dropdown-button:hover {
      outline: none;
      text-decoration: none;
    }
    .c-button--dropdown {
      position: relative;
      padding: 1em;
      background-color: #f1f1f1;
      line-height: 16px;
      transition: background-color .2s linear;
      min-height: 48px;
      text-align: left;
      color: #54575f;
      border: 1px solid #c9cdd7;
      margin-top: 3px;
    }
    .dropdown-button {
      -webkit-appearance: none;
      margin: 0;
      border: 0;
      overflow: visible;
      font: inherit;
      line-height: inherit;
      text-transform: none;
      display: inline-block;
      vertical-align: middle;
      text-decoration: none;
      text-align: center;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 1em .5em;
      width: 100%;
      color: #fff;
      background: #3b81a3;
    }
  }
}

.highlight-article {

  & figure {
    display: block;
    margin-bottom: var(--unit-4);

    @media (--small-viewport-max) {
      margin-bottom: var(--unit-2);
    }

    & img {
      width: 100%;
    }
  }

  & .date-author {
    & p {
      margin-bottom: var(--unit-1);
    }
  }

  & .highlight-headline {
    font-size: var(--mobile-h1);

    @media (--small-viewport) {
      font-size: var(--h1);
    }
  }

  & .subtitle {
    margin-bottom: var(--unit-4);

    @media (--small-viewport-max) {
      margin-top: var(--unit-1);
      margin-bottom: var(--unit-2);
    }
  }

  & .text-preview {
    margin-bottom: var(--unit-5);

    @media (--small-viewport-max) {
      margin-bottom: var(--unit-2);
    }
  }
}

.hero-blog {
  & .inner {
    background: var(--brand-primary);
    width: 100%;
    display: flex;
    align-items: center;

    & .grid-wrapper {
      width: 100%;
      padding-top: var(--unit-4);
      padding-bottom: var(--unit-4);
    }

    & h1 {
      color: var(--white);
      max-width: 500px;
      text-transform: uppercase;
      font-weight: bold;
      font-size: var(--mobile-h2);

      @media (--tablet-viewport) {
        font-size: var(--h2);
      }

      @media (--desktop-viewport) {
        font-size: var(--h1);
      }
    }

    & h3 {
      color: var(--white);
      text-transform: uppercase;
      font-size: var(--mobile-h4);

      @media (--tablet-viewport) {
        font-size: var(--h3);
      }
    }
  }
}
.map-module {
  & h2 {
    margin-bottom: var(--unit-2);
  }

  & p {
    margin: var(--unit-3) 0;
  }

  & .googlemap {
    height: 350px;
  }
}

.hero-image {
  & .title.mobile {
    @media (--desktop-viewport) {
      display: none;
    }
  }

  & .inner {
    position: relative;

    & .grid-wrapper.title.desktop {
      padding-top: 1.5em;
      display: none;

      @media (--desktop-viewport) {
        display: block;
      }

      & h1 {
        margin-bottom: 0;
      }

      @media (--desktop-viewport) {
        position: absolute;
        width: 100%;
        bottom: var(--unit-3);
        left: 0;
        right: 0;
        padding-bottom: 1.5em;

        & h1 {
          color: var(--white);
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }

    & img {
      height: 230px;
      width: 100%;
      object-fit: cover;

      @media (--desktop-viewport) {
        height: 480px;
      }
    }

    & .gradient {
      display: none;
      position: absolute;
      width: 100%;
      height: 40%;
      left: 0;
      bottom: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));

      @media (--desktop-viewport) {
        display: block;
      }
    }
  }
}
.hero-carousel {
  width: 100%;

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  & .carousel-inner,
  & .carousel-item,
  & .carousel {
    height: 100%;
  }

  & .carousel {
    height: 462px;
    overflow: hidden;

    @media (--desktop-viewport) {
      height: 700px;
      margin-bottom: 0;
    }
  }

  & .carousel-indicators {
    top: 100%;
    z-index: 2;
    list-style: none;

    & li {
      margin: 10px 5px;
    }

    @media (--desktop-viewport) {
      top: auto;

      & li {
        background-color: var(--white);
      }
    }

    & li {
      width: 12px;
      height: 12px;
      opacity: 1;
      background-color: var(--gray-light);

      &.active {
        background-color: var(--brand-primary);
      }
    }
  }

  & .carousel-item {
    position: relative;

    & .caption {
      position: absolute;
      bottom: -2px;
      max-width: 100%;
      font-size: var(--fontsize-smaller);
      color: var(--white);
      font-weight: bold;
      transition: all 0.3s ease-out;
      max-width: 40%;
      right: 2em;
      opacity: 1;
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.85);

      @media (--tablet-viewport) {
        opacity: 0;
      }
    }

    & a:focus {
      & .highlight {
        outline: -webkit-focus-ring-color auto 1px;
      }
    }

    & .highlight {
      position: absolute;
      bottom: 4em;
      background: var(--black-overlay);
      z-index: 2;
      padding: 2em;
      width: 60%;
      min-width: 300px;
      max-width: calc(100% - 2em);

      @media (--small-viewport) {
        padding: 2em 4em;
        width: 500px;
        min-width: none;
      }

      &:hover svg {
        transform: translateX(8px);
        opacity: 0.7;
      }

      & h1 {
        color: var(--white);
        font-weight: bold;
        text-transform: uppercase;
      }

      & h3 {
        color: var(--white);
        margin: 0.7em 0 0;

        @media (--small-viewport) {
          margin: 0.7em 0 0.2em;
        }
      }

      & p {
        margin: 0 0 var(--unit-1);

        @media (--small-viewport) {
          margin: 0 0 var(--unit-2);
        }

        color: var(--white);
        font-weight: normal;
      }

      & .highlight--link {
        display: flex;
        justify-content: flex-end;

        & svg {
          width: 30px;
          height: 30px;
          transition: all 0.25s ease-out;
          stroke: var(--white);
        }
      }
    }
  }

  & .carousel-control {
    width: auto;
    padding: 1em;
    transition: left 0.3s, right 0.3s;

    &.carousel-control-prev {
      @media (--tablet-viewport) {
        left: -100px;
      }
    }

    &.carousel-control-prev:focus {
      left: 0;
      outline: -webkit-focus-ring-color auto 1px;
    }

    &.carousel-control-next {
      @media (--tablet-viewport) {
        right: -100px;
      }
    }

    &.carousel-control-next:focus {
      right: 0;
      outline: -webkit-focus-ring-color auto 1px;
    }

    & svg {
      stroke: white;
      width: 2em;
    }
  }

  &:hover {
    & .carousel-control-prev {
      left: 0;
    }

    & .carousel-control-next {
      right: 0;
    }
  }

  & *:hover {
    & .caption {
      opacity: 1;
      transform: translateY(-10px);
    }
  }
}
.images {
  & figure {
    & picture img {
      width: 100%;
    }
    & figcaption {
      width: 100%;
      margin-top: var(--unit-2);
      font-family: var(--font-Family);
    }
  }

  &.same-height {
    & figure {
      & picture {

        & img {
          object-fit: cover;
        }
      }
    }
  }
}

.anchor-links {
  & a {
    background: var(--gray-light);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: var(--unit-1) 0;
    color: var(--gray);
    text-transform: uppercase;
    padding: 1em;

    & span {
      text-overflow: ellipsis;
      overflow: hidden;
      /* width: 50px; */
      white-space: nowrap;
    }

    & svg {
      fill: none;
      stroke: var(--gray);
      height: 20px;
      width: 20px;
      margin-left: 10px;
    }
  }
}

.filter {
  & .inner {
    padding-top: var(--unit-3);
    border-top: 1px solid var(--gray-dark);

    & h4 {
      margin-bottom: var(--unit-1);
    }
  }

  & .tags {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;

    @media (--tablet-viewport) {
      align-items: center;
      justify-content: flex-start;
    }
  }

  & .tag-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (--tablet-viewport) {
      flex-direction: row;
    }

    & span {
      padding: 9px 0 5px;
      margin-bottom: 0.7em;
      cursor: pointer;
      line-height: var(--fontsize-normal);
      transition: padding 0.2s ease, color 0.2s ease, background-color 0.2s ease;

      @media (--tablet-viewport) {
        margin-right: var(--unit-4);
      }
    }

    & span.active {
      padding: 9px 40px 5px 15px;
      background-color: #f6f6f6;
      color: var(--brand-primary);
      border-radius: 30px;
      background-repeat: no-repeat;
      background-position: 90% 50%;
      background-size: 22px;
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzBweCIgaGVpZ2h0PSIzMHB4IiB2aWV3Qm94PSIwIDAgMzAgMzAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDYxLjIgKDg5NjUzKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5pY29uL2Nsb3NlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9Imljb24vY2xvc2UiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwYXRoIGQ9Ik04LDggTDIyLjE0MjEzNTYsMjIuMTQyMTM1NiBNOC4xMzY3OTE0OSwyMi4xNTI2NTggTDIyLjI3ODkyNzEsOC4wMTA1MjI0MiIgaWQ9IkNvbWJpbmVkLVNoYXBlIiBzdHJva2U9IiM3NTc5ODIiIHN0cm9rZS13aWR0aD0iMiI+PC9wYXRoPgogICAgPC9nPgo8L3N2Zz4=');
    }
  }

  & .tags span {
    font-family: 'DIN Next W01', Helvetica, sans-serif;
    color: var(--gray-dark);
    font-size: var(--fontsize-normal);
    text-transform: uppercase;
  }
}
.accordeon {
  & .card {
    border: none;
    width: 100%;
    margin: 0;

    & .heading {
      padding: 0 1em;
      cursor: pointer;

      @media (--full-grid-viewport) {
        padding-left: 0;
        padding-right: 0;
      }

      & button {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: var(--unit-2) 0 !important;
        border-bottom: 1px solid var(--gray-light);
        font-weight: bold;
        color: var(--gray-darkest);

      & .accordeon-heading {
          font-weight: bold;
          font-family: var(--font-Family);
          margin: 2px 0 0 0;
        }

        & svg {
          stroke: var(--brand-primary);
          height: 30px;
        }

        &:active {
          background: none;
          outline: none;
        }

        & svg {
          transition: transform 0.3s ease;
        }

        &:not(.collapsed) {
          border-bottom: 1px solid transparent;
          & svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  & .card-body {
    color: var(--gray);
    padding: 0;
    margin: 1.25rem var(--unit-2);

    @media (--full-grid-viewport) {
      padding: 1.25rem var(--unit-2);
      margin: 0;
    }

    & figure {
      @media (--tablet-viewport) {
        padding-right: 0;
      }

      & figcaption {
        width: 100%;
        margin-top: 1em;
        font-family: DIN Next W01, Arial, sans-serif;
      }

      & img {
        width: 100%;
      }
    }
  }

  & .show .card-body {
    border-bottom: 1px solid var(--gray-light);
  }

  & .collapse {
      overflow-x: hidden;

  & img {
      max-width: 100%;
    }
  }

  &.variant-s {
    font-size: var(--mobile-h3);

    @media (--small-viewport) {
      font-size: var(--h3);
    }
  }

  &.variant-l {
    font-size: var(--mobile-h2);

    @media (--small-viewport) {
      font-size: var(--h2);
    }
  }
}

.info-module {
  & h2 {
    margin-bottom: var(--unit-2);
  }
}

.linklist {
  padding: var(--unit-5);
  background: var(--gray-lighter);

  & h3 {
    margin-bottom: var(--unit-2);
  }

  & ul {
    list-style-position: outside;
    padding: 0 0 0 var(--unit-3);
  }

  & li {
    margin-bottom: var(--unit-1);

    & a {
      color: var(--brand-primary);
      font-weight: 300;
      text-decoration: underline;

      &:hover {
        color: var(--blue-lighter);
      }
    }
  }
}

.page-title {
  padding-bottom: var(--unit-5);
}

section.link_module {
  & h1 {
    margin-bottom: var(--unit-1);
  }

  & .link_module__tiles {
    & a {
      &:hover {
        & figure {
          &:after {
            background: rgba(0, 0, 0, 0);
          }
        }
      }
    }

    & a {
      display: block;
      border-bottom: 4px solid rgba(0, 0, 0, 0);

      @media (--tablet-viewport) {
        float: left;
        width: 50%;

        &:nth-child(even) {
          border-left: 2px solid rgba(0, 0, 0, 0);
          padding-left: 0;
        }

        &:nth-child(odd) {
          border-right: 2px solid rgba(0, 0, 0, 0);
          padding-right: 0;
        }
      }
    }

    & figure {
      display: block;
      position: relative;
      height: 100%;
      margin: 0;
      -webkit-margin-after: 0em;

      &:after {
        content: "";
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;

        @media (--tablet-viewport) {
          background: rgba(84, 87, 95, 0.6);
        }
      }

      & picture {
        display: block;
        height: 100%;
        max-height: 120px;
        @media (--tablet-viewport) {
          max-height: 150px;
        }
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    & figcaption {
      @apply --center-vertical;
      z-index: 2;
      left: var(--unit-2);
      color: white;
      text-transform: uppercase;
      text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
      font-size: var(--mobile-h3);

      @media (--desktop-viewport) {
        left: var(--unit-4);
      }

      & span {
        @apply --clearfix;
      }
    }
  }
}

.tab-module {
  position: relative;
  padding-bottom: var(--unit-2);
  border-bottom: solid 1px var(--gray-light);

  @media (--tablet-viewport) {
    padding-bottom: 0;
    border-bottom: none;
  }

  & h3 {
    margin-top: var(--unit-1);
    margin-bottom: var(--unit-2);

    @media (--tablet-viewport) {
      margin-top: 0;
      margin-bottom: var(--unit-3);
    }
  }

  & .tabs {
    & .tabs-wrapper {
      padding-right: 0;
    }

    & .tab {
      min-height: 0;
      width: 100%;
      display: none;
      padding: var(--unit-3) var(--unit-2) !important;
      font-weight: 500;
      font-family: var(--font-Family);
      color: var(--gray-darkest);
      text-transform: uppercase;
      cursor: pointer;
      border-top: 1px solid var(--gray-lighter);

      transition: background 0.2s ease, border 0.2s ease, color 0.2s ease,
      font-weight 0.2s ease;

      @media (--tablet-viewport) {
        display: block;
      }
      &.show {
        background: var(--gray-lighter);
        border-left: 4px solid var(--brand-primary);
        color: var(--brand-primary);
        font-weight: 700;
      }
    }

    & select {
      display: block;
      margin: 15px 0;
      padding-left: 0;
      outline: none;
      border: none;
      font-family: var(--font-Family);

      &.custom-select {
        background: url(../img/svg/updown.svg) no-repeat right 12px center/30px
        30px;

        &:focus {
          outline: none;
          border: none;
        }
      }

      @media (--tablet-viewport) {
        display: none;
      }
    }

    background: var(--gray-lighter);
    @media (--tablet-viewport) {
      background: none;
    }
  }

  & .valuelist {
    height: calc(100% - 5em);

    & a {
      max-width: 100%;
      width: 100%;
      flex: auto;
    }
  }

  & .content-wrapper {
    & > .container-fluid {
      padding: 0;
      display: none;
      width: 100%;
      height: 100%;

      @media (--tablet-viewport) {
        padding: 0 15px;
      }

      &.show {
        display: flex;

        & .inner {
          animation: slide-up 0.7s ease-out;
        }
      }
    }

    @media (--tablet-viewport) {
      padding-left: 0;
      padding-right: 15px;
    }

    @media (--full-grid-viewport) {
      padding: 0;
    }

    & .content {
      background: var(--white);
      min-height: 100%;
      width: calc(100% + 2em);
      padding: var(--unit-2);

      @media (--tablet-viewport) {
        background: var(--gray-lighter);
        padding: var(--unit-5);
      }

      & > .col-12 {
        padding: 0;

        @media (--tablet-viewport) {
          padding: 0 15px;
        }
      }

      & button.inverted {
        @media (--tablet-viewport-max) {
          background: var(--brand-secondary);
          color: var(--blue-darkest);

          & svg {
            stroke: var(--blue-darkest);
          }

          &:hover {
            color: var(--blue-dark);

            & svg {
              stroke: var(--blue-dark);
            }
          }
        }
      }

      & .button-content {
        margin-top: var(--unit-3);

        @media (--tablet-viewport) {
          margin-top: var(--unit-4);
          margin-bottom: var(--unit-5);
        }

        & > div:first-of-type {
          margin-bottom: var(--unit-3);

          @media (--tablet-viewport) {
            margin-bottom: 0;
          }
        }
      }
    }

    & h4 {
      text-transform: none;
    }
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
.text-modal {
  & .modal-dialog {
    max-width: 980px;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    & .modal-content {
      border-radius: 0;
      border: none;
      border-top: 4px solid var(--brand-primary);
      padding-top: var(--unit-2);
      overflow: scroll;
      align-self: flex-start;
      height: 80vh;
      box-shadow: 0 8px 36px 0 rgba(0, 0, 0, 0.18);

      @media (--tablet-viewport) {
        align-self: center;
        height: 100%;
      }

      &.wide {
        max-height: 450px;
      }

      &.high {
        max-width: 650px;

        & .modal-content-wrapper {
          @media (--tablet-viewport) {
            max-height: 70vh;
          }
        }
      }

      & .close {
        opacity: 1;

        & svg {
          stroke: var(--gray-darker);
          width: 30px;
          height: 30px;
        }
      }
    }

    @media (--tablet-viewport) {
      margin: 1.75rem auto;
      padding: var(--unit-1);

      & .modal-body {
        padding: var(--unit-4);
      }

      & .modal-content {
        border-radius: 0;
        border: 0;
        padding: 0;
      }
    }

    & .modal-content-wrapper {
      @media (--tablet-viewport-max) {
        column-count: unset;
      }
    }
  }
}

.modal-dialog {
  @media (--tablet-viewport-max) {
    max-width: initial;
    height: 100%;
    margin: 0;

    & .modal-content {
      height: 100%;

      & .modal-body {
        background: var(--white);

        & .container > div:last-of-type {
          padding: var(--unit-2);
        }
      }
    }
  }
}

button.text-modal-trigger {
  position: relative;
  margin: 0;
  padding: 0 !important;
  min-height: 0;
  color: var(--brand-primary);
  font-weight: 400;
  font-family: var(--font-Family);
  text-decoration: underline;
  text-transform: initial;

  &:after {
    position: absolute;
    left: 100%;
    content: url(../img/svg/info-link.svg);
    margin-left: 2px;
    margin-top: 3px;
  }
}
.valuelist {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .list {
    margin-bottom: var(--unit-2);

    & .wrapper {
      display: flex;
      justify-content: space-between;
      padding-right: 0;
      margin-bottom: var(--unit-3);

      &:last-of-type {
        margin-bottom: 0;

        & > * {
          margin-bottom: 0;
        }
      }

      & p {
        flex: 0 0 60%;
        max-width: 60%;

        @media (--small-viewport) {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }

      & .value {
        padding: 0;
        flex: 0 0 30%;
        font-weight: 500;

        @media (--desktop-viewport) {
          padding-left: 30px;
          padding-right: 15px;
        }

        @media (--small-viewport) {
          flex: 0 0 41.666667%;
        }
      }
    }
  }
}

.quote {
  padding-left: 0;
  margin-left: 0;

  & .quotetext {
    color: var(--brand-primary);
    font-weight: 500;
    margin-bottom: var(--unit-2);
  }

  & .author {
    color: var(--gray-dark);
    text-align: right;
  }
}

.nav-icon {
  width: 25px;
  height: 16px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;

  & span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: var(--gray);
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 7px;
    }

    &:nth-child(4) {
      top: 14px;
    }
  }

  &:hover span {
    background: var(--brand-primary);
  }
}

.navbar-toggler:not(.collapsed) .nav-icon {
  & span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  & span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  & span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  & span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
}
.navigation-shortcuts {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: var(--brand-primary);
  display: flex;
  justify-content: center;
  z-index: 6;

  &.mobile {
    display: none;
    position: relative;
    background: transparent;
    justify-content: flex-start;
    flex-shrink: 0;
    width: calc(100% - 4em);

    @media (--desktop-viewport) {
      display: none;
    }

    & .content {
      height: 0px;
      margin-top: 24px;
      margin-right: 5px;
      margin-bottom: 10px;
      justify-content: space-between;
      align-items: flex-start;
      overflow: visible;
      opacity: 1;
      transition: height 0.3s linear, opacity 0.3s ease;

      & .link-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        margin-right: 10px;

        & a {
          margin: 0 0 3px;
          padding: 0;
          height: fit-content;

          &:hover {
            background: transparent;
            opacity: 1;
            text-decoration: underline;
          }
        }
      }

      & a,
      & .dropdown,
      & .dropdown button {
        opacity: 1;
        color: var(--gray-darker);
      }

      & .dropdown {
        margin: -0.35em 0 0 0;

        & .dropdown-menu {
          text-transform: uppercase;
        }
      }
    }
  }

  & .content {
    max-width: var(--full-width);
    width: 100%;
    height: 3px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    transition: height 0.3s linear;

    @media (--desktop-viewport) {
      height: 2em;
      overflow: initial;
    }

    & a {
      font-size: var(--fontsize-small);
      padding: 0.5em 1em;
      font-weight: normal;
      height: 32px;

      &:hover {
        opacity: 0.8;
        background: var(--blue-darker);
      }
    }

    & a,
    & button {
      transition: opacity 0.2s;
      opacity: 0;

      @media (--desktop-viewport) {
        opacity: 1;
      }
    }

    & .dropdown {
      margin-top: -2px;
      margin-right: 1em;

      @media (--full-grid-viewport) {
        margin-right: 0;
      }

      &:hover {
        & button {
          opacity: 0.8;
          background: var(--blue-darker);
        }
      }

      & button {
        padding: 0.5em 1em;
        margin: 0;
        font-size: 14px;
        color: #fff;
        line-height: 1.3;

        &:active {
          opacity: 1;
          background: transparent;
        }
      }

      & .dropdown-menu {
        width: 100%;
        padding: 0;
        min-width: 0;
        margin-top: 6px;

        & a  {
          height: auto;
          margin: auto;
          padding: 1em 1em;
          color: var(--gray-darker);
          text-transform: uppercase;

          &:hover {
            color: var(--brand-primary);
            background: var(--gray-lighter);
          }
        }
      }

      & .dropdown-button {
        & svg {
          stroke-width: 3px;
          margin-left: 4px;
          margin-right: 0;
          height: 18px;
          width: 18px;

          @media (--desktop-viewport) {
            stroke: var(--white);
          }
        }
      }
    }
  }
}

.navigation-shortcuts.scroll {
  @media (--desktop-viewport) {
    & .content {
      height: 3px;
      overflow: hidden;

      & a,
      & button {
        opacity: 0;
      }
    }
  }
}

.content.menu-open .navigation-shortcuts.mobile {
  display: flex;

  & .content {
    height: fit-content;
    opacity: 1;
  }
}
.spacing-xl {
  height: var(--unit-5);
  padding: var(--unit-5);
}

.spacing-l {
  height: var(--unit-4);
  padding: var(--unit-4);
}

.spacing-m {
  height: var(--unit-3);
  padding: var(--unit-3);
}

.spacing-s {
  height: var(--unit-2);
  padding: var(--unit-2);
}

.spacing-xs {
  height: var(--unit-1);
  padding: var(--unit-1);
}

.spacing-top {
  height: var(--spacing-top-mobile);
  padding: var(--spacing-top-mobile);

  @media (--desktop-viewport) {
    height: var(--spacing-top-desktop);
    padding: var(--spacing-top-desktop);
  }
}

.spacing-top-small {
  height: var(--spacing-top-mobile);
  padding: var(--spacing-top-mobile);

  @media (--desktop-viewport) {
    height: var(--spacing-top-small);
    padding: 0;
  }
}
dialog[open].info-popup {
    display: flex;
}

dialog.info-popup {
    position: fixed;
    bottom: var(--unit-5);
    right: var(--unit-1);
    left: var(--unit-1);
    margin: 0;
    padding: var(--unit-2);
    width: calc(100% - var(--unit-1) * 2);
    z-index: 1;
    border: 2px solid var(--gray-darkest);
    flex-direction: column;
    gap: var(--unit-2);

    @media (--tablet-viewport) {
        max-width: 500px;
        width: calc(100% - var(--unit-2) * 2);
        bottom: var(--unit-2);
        right: var(--unit-2);
        left: var(--unit-2);
        padding: var(--unit-3);
    }

    button.close {
        position: absolute;
        top: 0;
        right: 0;
        padding: var(--unit-1);

        @media (--tablet-viewport) {
            padding: var(--unit-2);
        }

        &:hover svg {
            stroke: var(--brand-primary);
        }

        svg {
            width: 30px;
            height: 30px;
            margin: 0;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 0.25em;

        h3 {
            margin-right: var(--unit-3-rem);
            margin-bottom: 0;
        }

        p {
            margin: 0;
        }
    }

    .link {
        button {
            width: fit-content;
            color: var(--brand-primary);
            margin: 0;

            span {
                margin-right: var(--unit-2-rem);
            }

            svg {
                height: 16px;
                width: 28px;
            }

            &:hover {
                color: var(--blue-darkest);

                svg {
                    stroke: var(--blue-darkest);
                    transform: translateX(5px);
                }
            }
        }
    }
}
#blog-articles {
  & .teaser {
    margin-bottom: 100px;
  }

  & .lazy-load-button {
    margin: 0 auto;

    &:hover {
      & svg {
        transform: none;
      }
    }
  }

  & .blog-filters {
    margin-bottom: 50px;
  }

  & button {
    transition: opacity 0.3s ease;
  }

  & button:disabled {
    opacity: 0.4;
  }
}

#default-articles {
  & .teaser {
    margin-bottom: 100px;
  }

  & .lazy-load-button {
    margin: 0 auto;

    &:hover {
      & svg {
        transform: none;
      }
    }
  }

  & .blog-filters {
    margin-bottom: 50px;
  }

  & button {
    transition: opacity 0.3s ease;
  }

  & button:disabled {
    opacity: 0.4;
  }
}

#exhibition-articles {
  margin: 70px 0;

  & .teaser {
    margin-bottom: 70px;
  }

  & .lazy-load-button {
    margin: 0 auto;

    &:hover {
      & svg {
        transform: none;
      }
    }
  }

  & button {
    transition: opacity 0.3s ease;
  }

  & button:disabled {
    opacity: 0.4;
  }
}

.teaser {
  position: relative;

  & > .row {
    width: 100%;
    margin: 0;
  }

  & .teaser-block {
    display: block;
    padding-left: 0;
    padding-right: 0;

    @media (--desktop-viewport) {
      margin-right: 1em;
    }

    &:last-of-type {
      margin-bottom: 0;
      margin-right: 0;
    }

    margin-bottom: var(--unit-4);

    @media (--desktop-viewport) {
      margin-bottom: 0;
    }

    & img {
      margin: 0;
    }

    & .content-wrapper {
      margin-top: var(--unit-3);
      padding: 0 0.3em;

      & .row {
        height: 100%;
      }

      & h2 {
        text-transform: uppercase;
        font-weight: bold;
      }

      & .kicker {
        margin-bottom: 0.5em;
      }

      & .kicker {
        color: var(--brand-primary);
      }

      & .button--wrapper {
        display: block;

        @media (--tablet-viewport) {
          max-width: 280px;
        }
      }

      & button.more {
        display: none;
      }
    }
  }

  & picture {
    padding: 0;
    display: block;
    max-width: none;
    max-height: 350px;
    overflow: hidden;

    & a:focus {
      display: initial;
    }

    @media (--desktop-viewport) {
      overflow: visible;
    }

    & img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &.xl {
    @media (--desktop-viewport) {
      & picture {
        max-height: none;
      }
    }

    & .align {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.l,
  &.marginal {
    & .teaser-block {
      @media (--desktop-viewport) {
        display: flex;

        & picture {
          min-height: 440px;
        }
      }

      & .content-wrapper {
        @media (--desktop-viewport) {
          margin-top: 0;
          padding: var(--unit-2) var(--unit-2) 0 var(--unit-4);

          & h2 {
            margin: 0;
          }

          & .teaser-content {
            margin: var(--unit-2) 0 var(--unit-4);
          }
        }
      }
    }
  }

  &.l {
    picture {
      width: 100%;
      @media (--desktop-viewport) {
        width: 66.66666667%;
      }
    }
  }

  &.m {
    & .teaser-block {
      width: 100%;

      & picture {
        width: 100%;
      }

      @media (--desktop-viewport) {
        width: calc(50% - 0.5em);

        & picture {
          height: 400px;
          max-height: none;
        }

        & .content-wrapper {
          padding: 0;
        }
      }
    }
  }

  &.s {
    & .teaser-block {
      width: 100%;

      & picture {
        width: 100%;
      }

      @media (--desktop-viewport) {
        width: calc((100% / 3) - (1em * (2 / 3)));

        & picture {
          height: 280px;
        }

        & .content-wrapper {
          padding: 0;
        }
      }

      @media (--desktop-viewport-max) {
        width: 100%;
      }
    }

    & .content-wrapper {
      @media (--desktop-viewport-max) {
        & h4:first-of-type {
          font-size: var(--mobile-h4);
          margin-bottom: var(--unit-1);
        }

        & h3 {
          font-size: var(--h2);
          margin-bottom: 0.1em;
        }

        & h4:last-of-type {
          font-size: var(--mobile-h2);
          margin-bottom: var(--unit-4);
        }
      }

      @media (--tablet-viewport-max) {
        & h4:first-of-type {
          font-size: var(--mobile-h4);
          margin-bottom: var(--unit-1);
        }

        & h3 {
          font-size: var(--mobile-h1);
          margin-bottom: 0.2em;
        }

        & h4:last-of-type {
          font-size: var(--mobile-h3);
          margin-bottom: var(--unit-4);
        }
      }
    }
  }

  &.l {
    @media (--desktop-viewport) {
      & .read-more-container.closed {
        & .content-wrapper {
          & .teaser-content {
            height: calc((var(--fontsize-normal) * 1.5) * 8) !important;

            &.ellipsis {
              -webkit-line-clamp: 8;
            }
          }
        }
      }
    }
  }

  &.xl,
  &.l,
  &.m {
    @media (--desktop-viewport-max) {
      & h4 {
        font-size: var(--mobile-h4);
        margin-bottom: var(--unit-1);
      }

      & h2 {
        font-size: var(--h2);
        margin-bottom: 0.1em;
      }

      & h3 {
        font-size: var(--mobile-h2);
        margin-bottom: var(--unit-4);
      }
    }

    @media (--tablet-viewport-max) {
      & h4 {
        font-size: var(--mobile-h4);
        margin-bottom: var(--unit-1);
      }

      & h2 {
        font-size: var(--mobile-h1);
        margin-bottom: 0.2em;
      }

      & h3 {
        font-size: var(--mobile-h3);
        margin-bottom: var(--unit-4);
      }
    }
  }

  &.m,
  &.s {
    & .teaser-block {
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.blog {
    & .content-wrapper {
      margin-top: var(--unit-4);
      margin: var(--unit-2);
      width: calc(100% - var(--unit-4));
    }

    & .teaser-block {
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
      margin-bottom: var(--unit-4);
      cursor: pointer;

      @media (--desktop-viewport) {
        margin-bottom: 0;
      }

      &:hover {
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.18);
        transform: translateY(-2px);
      }

      &.scroll-animation-finished {
        transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
      }

      & .content-wrapper {
        padding-right: 15px;
        padding-left: 15px;

        & h4 {
          margin-bottom: var(--unit-2);
          color: var(--gray-darkest);
        }
      }
    }

    & .teaser-block:last-of-type {
      margin-bottom: 0;
    }

    & .teaser-content {
      display: flex;
      flex-direction: column;
    }

    & .button--wrapper {
      align-self: flex-end;
      margin-right: -24px;
    }

    &.m,
    &.s {
      & .content-wrapper {
        padding: var(--unit-1) var(--unit-1) 0;

        & h3 {
          font-size: var(--mobile-h1);
          margin-bottom: 0.2em;

          @media (--desktop-viewport) {
            font-size: var(--h3);
          }
        }

        & h4 {
          font-size: var(--mobile-h3);
          margin-bottom: var(--unit-2);

          @media (--desktop-viewport) {
            font-size: var(--h4);
          }
        }
      }
    }
  }

  &.exhibition {
    &.l {
      & .content-wrapper {
        @media (--desktop-viewport) {
          margin-top: 3.5em;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }

      & .teaser-block {
        display: flex;
        flex-direction: column;
        width: 100%;

        @media (--desktop-viewport) {
          flex-direction: row;
        }
      }
    }

    &.s,
    &.m {
      & .teaser-block {
        @media (--desktop-viewport) {
          display: flex;
          flex-direction: column;

          & .content-wrapper {
            height: 100%;
            flex-shrink: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
        }
      }
    }

    & .teaser-block {
      background: var(--gray-lighter);

      & .content-wrapper {
        padding: var(--unit-1) var(--unit-3);

        & h4.kicker {
          color: var(--brand-primary);
        }
      }

      & .text-content {
        margin-top: var(--unit-5);
        @media (--tablet-viewport) {
          margin-top: 4em;
        }
      }

      & .button--wrapper {
        margin-top: var(--unit-5);
        margin-bottom: var(--unit-2);
        max-width: 215px;
      }

      & .button--primary.inverted {
        background-color: transparent;
        padding-left: 0 !important;
      }
    }
  }
}

/* Fix layout bug in live page with blog teaser component */
.grid-wrapper:has(.teaser-module) > .row {
  width: 100%;
}
#lazy-load-teasers {
  & .lazy-load-button {
    @media (--tablet-viewport) {
      max-width: 250px;
    }

    &:hover {
      & svg {
        transform: none;
      }
    }
  }
}
.teaser-cta {
  position: relative;

  & .content-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    max-width: none;
    left: 0;
    right: 0;
    width: 100%;

    & .inner {
      height: 100%;

      @media (--tablet-viewport-max) {
        height: calc(100% - 3em);
        margin-top: 2em;
        margin-bottom: 1em;
        justify-content: space-between;
        flex-direction: column;
      }

      @media (--tablet-viewport) {
        align-items: center;
      }
    }

    & h2 {
      color: var(--white);
      text-transform: uppercase;
      font-weight: bold;
    }

    & p,
    & .text {
      margin: 1em 0;
      color: var(--white);
      font-family: var(--font-Family);
    }
  }

  & picture {
    position: relative;

    & img {
      height: 500px;
      margin: 0;
      width: 100%;
      object-fit: cover;

      @media (--full-grid-viewport) {
        height: 650px;
        margin: 0 1em;
        width: calc(100% - 2em);
      }

      @media (--full-grid-viewport) {
        margin: 0;
        width: 100%;
      }
    }
  }
}